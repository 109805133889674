import React from 'react'
import PropTypes from 'prop-types'
import { StyledCopyrightText, StyledLink, StyledAnchor } from '../styles'
import { useHover } from '../../../hooks'

const LinkWrapper = ({ to, href, children, ...rest }) => {
  if (to) {
    return (
      <StyledLink to={to === 'home' ? '/' : to} {...rest}>
        {children}
      </StyledLink>
    )
  } else {
    return (
      <StyledAnchor target="__blank" rel="noopener noreferrer" {...rest}>
        {children}
      </StyledAnchor>
    )
  }
}

const FooterSocialLink = ({
  navigationLink: { url, slug, displayText },
  theme,
}) => {
  const [hoverRef, isHovered] = useHover()

  return (
    <LinkWrapper to={slug} href={url}>
      <StyledCopyrightText
        display="inline-block"
        borderLeft="1px solid"
        borderColor={`${theme}.light.text`}
        color={isHovered ? `${theme}.textAlt` : `${theme}.light.text`}
        ref={hoverRef}
        px={2}
      >
        {displayText}
      </StyledCopyrightText>
    </LinkWrapper>
  )
}

FooterSocialLink.propTypes = {
  displayText: PropTypes.string,
  navigationLink: PropTypes.shape({
    url: PropTypes.string,
    slug: PropTypes.string,
    displayText: PropTypes.string,
  }),
}

export default FooterSocialLink
