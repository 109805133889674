import { useLayoutEffect } from 'react'

const useLockBodyScroll = () => {
  useLayoutEffect(() => {
    // Get original body overflow
    const originalOverflow = window.getComputedStyle(document.body).overflow
    const originalPosition = window.getComputedStyle(document.body).position
    const originalHeight = window.getComputedStyle(document.body).height

    // Prevent scrolling on mount
    document.body.style.overflow = 'hidden'
    document.body.style.position = 'fixed'
    document.body.style.height = '100%'

    // Re-enable scrolling when component unmounts
    return () => {
      document.body.style.overflow = originalOverflow
      document.body.style.position = originalPosition
      document.body.style.height = originalHeight
    }
  }, []) // Empty array ensures effect is only run on mount and unmount
}

export default useLockBodyScroll
