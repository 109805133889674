import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledMobileOverlayContainer,
  StyledNavigationItemsContainerMobile,
  StyledNavList,
  StyledListItem,
  StyledText,
} from '../styles'
import NavigationLogo from '../components/NavigationLogo'
import BackButton from '../components/BackButton'
import NavigationItemMobile from '../components/NavigationItemMobile'

// to do
const SubNavigationMobile = ({
  slideDirection = 'left',
  theme,
  logo,
  alt,
  showLogoOnOverlay,
  subNav: { title, navItems } = {},
  parentSlug,
  toggledSubNav,
  navbarOffsetTop,
  closeOverlay,
}) => {
  return (
    <StyledMobileOverlayContainer
      bg={`${theme}.bg`}
      color={`${theme}.text`}
      opacity=".925"
      position="fixed"
      left="0"
      toggled={toggledSubNav}
      slideDirection={slideDirection}
    >
      <StyledNavigationItemsContainerMobile
        px={3}
        pb={6}
        fontSize={0}
        position="absolute"
        top={navbarOffsetTop}
        bg={`${theme}.bg`}
        color={`${theme}.text`}
      >
        <StyledNavList
          bg={`${theme}.bg`}
          color={`${theme}.text`}
          fontFamily="headings"
        >
          <StyledListItem>
            <BackButton handleClick={closeOverlay}>Back</BackButton>
          </StyledListItem>
          {showLogoOnOverlay && <NavigationLogo logo={logo} alt={alt} />}
          <StyledListItem>
            <StyledText my={3} fontSize={4} fontWeight="500">
              {title}
            </StyledText>
          </StyledListItem>
          {navItems &&
            navItems.map(({ id, navigationLink, displayText }) => (
              <NavigationItemMobile
                key={id}
                displayText={displayText}
                {...navigationLink}
                uncategorized
                isMobile
                parentSlug={parentSlug}
                fontSize={8}
                theme={theme}
                ml={3}
              />
            ))}
        </StyledNavList>
      </StyledNavigationItemsContainerMobile>
    </StyledMobileOverlayContainer>
  )
}

SubNavigationMobile.propTypes = {
  slideDirection: PropTypes.string,
  theme: PropTypes.string,
  logo: PropTypes.string,
  alt: PropTypes.string,
  showLogoOnOverlay: PropTypes.bool,
  subNav: PropTypes.shape({
    title: PropTypes.string,
    navItems: PropTypes.arrayOf(PropTypes.object),
  }),
  parentSlug: PropTypes.string,
  toggledSubNav: PropTypes.bool,
  navbarOffsetTop: PropTypes.string,
  closeOverlay: PropTypes.func,
}

export default SubNavigationMobile
