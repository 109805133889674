import React from 'react'
import PropTypes from 'prop-types'
import { StyledList, StyledCopyrightText, StyledListItem } from '../styles'

const FooterCopyright = ({ copyright, bg, color }) => {
  if (!copyright) return null
  return (
    <StyledList flexDirection="column" bg={bg} color={color} m={0} p={3}>
      {copyright.map(({ id, displayText }) => (
        <StyledListItem
          key={id}
          my={[2, 2, 1]}
          justifyContent="center"
          alignItems="center"
        >
          <StyledCopyrightText fontWeight={100} textAlign="center">
            {displayText}
          </StyledCopyrightText>
        </StyledListItem>
      ))}
    </StyledList>
  )
}

FooterCopyright.propTypes = {
  copyright: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      displayText: PropTypes.string,
    })
  ),
  bg: PropTypes.string,
  color: PropTypes.string,
}

export default FooterCopyright
