import React from 'react'
import Nav from '../../../../common/components/Navigation'
import { useStaticQuery, graphql } from 'gatsby'
import logo from '../../assets/adotnetwork-logo.svg'

const Navigation = () => {
  const { header } = useStaticQuery(graphql`
    query HeaderQuery {
      header: allContentfulNavigation(
        filter: { placement: { eq: "Global Header" } }
      ) {
        nodes {
          navItems {
            ... on ContentfulNavigationItem {
              id
              category
              displayText
              navigationLink {
                ... on ContentfulPage {
                  __typename
                  slug
                  displayTextAlt: title
                }
                ... on ContentfulCompany {
                  __typename
                  id: contentful_id
                  displayTextAlt: title
                }
                ... on ContentfulExternalLink {
                  __typename
                  url
                  displayTextAlt: internalTitle
                }
              }
            }
          }
        }
      }
    }
  `)

  const navItems = header.nodes?.[0].navItems
  return (
    <Nav
      navItems={navItems}
      mobileOnly
      logo={logo}
      alt="a.network"
      navType="simpleL2R"
    />
  )
}

export default Navigation
