import React from 'react'
import PropTypes from 'prop-types'
import { useComponentTheme } from '../../../hooks'
import NavigationMobileSpread from './NavigationMobileSpread'
import NavigationMobileSimpleR2L from './NavigationMobileSimpleR2L'
import NavigationMobileSimpleL2R from './NavigationMobileSimpleL2R'

const NavigationMobile = ({ navType, theme: themeBool, ...props }) => {
  const { theme } = useComponentTheme(themeBool)

  switch (navType) {
    case 'spreadEvenly':
      return <NavigationMobileSpread theme={theme} {...props} />
    case 'simpleR2L':
      return <NavigationMobileSimpleR2L theme={theme} {...props} />
    case 'simpleL2R':
    default:
      return <NavigationMobileSimpleL2R theme={theme} {...props} />
  }
}

NavigationMobile.propTypes = {
  navItems: PropTypes.arrayOf(PropTypes.object),
  closure: PropTypes.object,
  logo: PropTypes.string,
  alt: PropTypes.string,
  theme: PropTypes.bool,
  py: PropTypes.number,
}

export default NavigationMobile
