import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledNavWrapper,
  StyledNavigation,
  StyledNavigationItemsContainer,
  StyledNavigationItem,
} from '../styles'
import NavigationItem from '../components/NavigationItem'
import Notification from '../components/notification'
import NavigationLogo from '../components/NavigationLogo'
import { zIndices } from '../../../themes'

const NavigationDesktopSpread = ({
  navItems,
  logo,
  alt,
  theme,
  homeSlug,
  preview,
}) => {
  const isActive = slug =>
    window?.location?.pathname.split('/')[1] === `${slug}`

  return (
    <StyledNavWrapper
      data-testid="navigation-desktop-spread"
      role="navigation"
      position="fixed"
      bg={`${theme}.bg`}
      color={`${theme}.text`}
      zIndex={zIndices.navigation}
    >
      <StyledNavigation
        justifyContent="space-between"
        maxWidth="maxWidth.xlargeNav"
        margin="0 auto"
      >
        <NavigationLogo logo={logo} alt={alt} homeSlug={homeSlug} />
        <StyledNavigationItemsContainer
          spreadNavigationItemsEvenly
          px={[0, 0, 3, 4, 5]}
        >
          {navItems &&
            navItems.map(({ id, displayText, navigationLink }) => (
              <NavigationItem
                key={id}
                displayText={displayText}
                {...navigationLink}
                theme={theme}
                stackText
                isActive={isActive(navigationLink.slug)}
                preview={preview}
                changeBgOnHover
              />
            ))}
        </StyledNavigationItemsContainer>
        <StyledNavigationItem>
          <Notification />
        </StyledNavigationItem>
      </StyledNavigation>
    </StyledNavWrapper>
  )
}

NavigationDesktopSpread.propTypes = {
  navItems: PropTypes.arrayOf(PropTypes.object),
  closure: PropTypes.object,
  logo: PropTypes.string,
  alt: PropTypes.string,
  theme: PropTypes.string,
  homeSlug: PropTypes.string,
  preview: PropTypes.bool,
}

export default NavigationDesktopSpread
