// Reference to how theme object interacts with styled system
// https://styled-system.com/api
// https://styled-system.com/guides/why-powers-of-two/

const cerulean = '#0072ce'
const softBlue = '#5bc2e7'
const charcoal = '#373a36'
const white = '#ffffff'
const buttonAccessible = '#009ccb'
const mediumGrey = '#7e7f74'
const oceanBlue = '#0067BA'
const paleGrey = '#f4f5f8'
const lightPeriwinkle = '#d9d9dd'
const brownishGrey = '#6d6d6d'
const steel = '#768692'
const putty = '#afa9a0'
const lightGrey = '#d9d9d6'
const brightBlueBorder = '#0091FF'
const teal = '#5BC2E7'
const fadedGrey = '#E6E6E6'

const black = '#000'
const darkGrey = '#191919'
const grey = 'grey'
const myColorYellow = '#fbe002'
const myColorBlue = '#2480c2'
const myColorRed = '#d22027'
const myColorGreen = '#219962'
const myColorPurple = '#654587'

const overlayMyColor = 'rgba(0,0,0,.7)'

// Default Breakpoints
export const breakpoints = ['40em', '52em', '64em', '70em', '82em', '1130px']
// @media screen and (min-width: 40em)
// @media screen and (min-width: 52em)
// @media screen and (min-width: 64em)

// coh
export const fontSizes = {
  // coh
  // paragraph text is 24 on mocks
  //    P,  H1, H2, H3, H4, H5, H6, Button 7, 8, 9
  coh: [24, 60, 45, 33, 33, 29, 18, 22, 20, 18, 16, 14, 12],
  // coh: [20, 56, 40, 32, 30, 28, 22, 20, 18, 16, 14, 12],
  // cohMocks: [24, 60, 45, 33, 33, 29, 22, 20, 18, 14, 12],

  anetworkFontSizes: [18, 64, 48, 32, 24, 20, 16, 14, '1.25rem'],

  socialindexFontSizes: [18, 60, 50, 35, 24, 20, 16, 14, '1.25rem'],
  // default
  defaultFontSizes: [12, 14, 16, 20, 24, 28, 32, 48, 64, 72],
}
export const lineHeights = [1, 3, 1.6, 1.6, 1.2, 1.2, 1.2, 1.2]
export const fontWeights = [300, 400, 400, 300]

/**
 * p
 * h1
 * h2
 * h3
 * h4
 * h5
 * h6
 * small
 *
 */

// default space for margin and padding
export const space = [0, 4, 8, 16, 32, 64, 128, 256, 512]
const blueHover = '#0072ce9c'
const palestOfBlues = '#F7FDFF'
const textAlt2 = '#32C5FF'
// background: #0072CE40 0% 0% no-repeat padding-box;

const instagramHoverBlue = '#c4e2fa'
const transparentBlue = '#0072CE40'

// #f4f5f8 is grey we want

const COH = {
  colors: {
    hero: cerulean,
    heroAlt: softBlue,
    white,
    paleGrey,
    brownishGrey,
    teal,
    instagramHoverBlue,
    black,
    dark: {
      bg: cerulean,
      bgAlt: oceanBlue,
      bgInvert: white,
      text: white,
      textAlt: cerulean,
      textInvert: cerulean,
      heroAlt: softBlue,
      border: brightBlueBorder,
      borderAlt: teal,
      btnBg: transparentBlue,
      btnHover: instagramHoverBlue,
      btnHoverAlt: blueHover,
      carouselDots: softBlue,
    },
    light: {
      // true, has white background
      bg: white,
      bgAlt: paleGrey,
      bgInvert: cerulean,
      text: brownishGrey,
      textAlt: cerulean,
      textInvert: white,
      heroAlt: softBlue,
      border: brightBlueBorder,
      borderAlt: teal,
      btnBg: transparentBlue,
      btnHover: cerulean,
      btnHoverAlt: palestOfBlues,
      carouselDots: softBlue,
    },
    blues: [cerulean, oceanBlue, blueHover, softBlue, textAlt2],
  },
  breakpoints,
  fontSizes: fontSizes.coh,
  lineHeights,
  shadows: {
    text: '0px 2px 8px #0000004f',
    bg: '2px 2px 2px #000',
  },
  fonts: {
    body:
      "'Open Sans', 'Arial', 'Verdana', '-apple-system', BlinkMacSystemFont, sans-serif",
    headings:
      "'Catamaran', 'Arial', 'Verdana', '-apple-system', BlinkMacSystemFont, sans-serif",
    nav:
      "'Open Sans', 'Arial', 'Verdana', '-apple-system', BlinkMacSystemFont, sans-serif",
  },
  sizes: {
    maxWidth: {
      xsmall: '30em',
      small: '40em',
      medium: '52em',
      large: '64em',
      xlarge: '1216px', // changed from 1180px to match nav
      xlargeNav: '1216px', // needed to even out evenly space logo for nav
      xxlarge: '1250px',
      xlargeCarousel: '1300px', // needed to match carousel frame and btns to navbar
      text: { xsmall: '100%', small: '91.67%', medium: '75%', large: '63%' },
    },
  },
  fontWeights: {
    regular: 300,
    semiBold: 400,
    bold: 600,
  },
  space,
  transitionDuration: '0.25s',
  richtext: 'default',
  borderRadius: false,
}

const anetwork = {
  colors: {
    hero: black,
    heroAlt: white,
    dark: {
      // false
      bg: black,
      bgAlt: darkGrey,
      text: white,
      textAlt: white,
      border: white,
      borderAlt: white,
    },
    light: {
      // true, has white background
      bg: white,
      bgAlt: paleGrey,
      text: black,
      textAlt: darkGrey,
      border: white,
      borderAlt: white,
    },
    carouselDots: grey,
    myColor: {
      yellow: myColorYellow,
      purple: myColorPurple,
      blue: myColorBlue,
      green: myColorGreen,
      red: myColorRed,
      black,
    },
  },
  breakpoints,
  fontSizes: fontSizes.anetworkFontSizes,
  fontWeights: {
    regular: 400,
  },
  sizes: {
    maxWidth: {
      xsmall: '30em',
      small: '40em',
      medium: '52em',
      large: '64em',
      xlarge: '1180px',
      xxlarge: '1250px',
      text: {
        xsmall: '100%',
        small: '91.67%',
        medium: '75%',
        large: '66.66667%',
      },
    },
  },
  lineHeights,
  shadows: {
    text: '0px 2px 8px #0000004f',
    bg: '2px 2px 2px #000',
  },
  fonts: {
    body: "'Open Sans',Helvetica,Helvetica Neue,Arial,sans-serif,sans-serif",
    headings: "'FS Joey',Helvetica,Helvetica Neue,Arial,sans-serif,sans-serif",
    nav: 'Helvetica Bold,Helvetica,Helvetica Neue,Arial,sans-serif,sans-serif',
  },
  space,
  transitionDuration: '0.25s',
  richtext: 'default',
  borderRadius: false,
}

const socialIndex = {
  colors: {
    hero: black,
    heroAlt: white,
    dark: {
      // false
      logoBg: fadedGrey,
      bg: black,
      txtBg: '#343334',
      bgAlt: darkGrey,
      text: white,
      textAlt: '#109771',
      border: white,
      borderAlt: white,
      btn: '#109771',
    },
    light: {
      // true, has white background
      logoBg: fadedGrey,
      txtBg: fadedGrey,
      bg: white,
      bgAlt: paleGrey,
      text: black,
      textAlt: darkGrey,
      border: white,
      borderAlt: white,
    },
    myColor: {
      yellow: myColorYellow,
      purple: myColorPurple,
      blue: myColorBlue,
      green: myColorGreen,
      red: myColorRed,
      black,
    },
  },
  breakpoints,
  fontSizes: fontSizes.socialindexFontSizes,
  fontWeights: {
    regular: 400,
  },
  sizes: {
    maxWidth: {
      xsmall: '30em',
      small: '40em',
      medium: '52em',
      large: '64em',
      xlarge: '1180px',
      xxlarge: '1250px',
      text: { xsmall: '100%', small: '80%', medium: '80%', large: '80%' },
    },
  },
  lineHeights,
  shadows: {
    text: '0px 1px 5px #0000009A',
    bg: '2px 2px 2px #000',
  },
  fonts: {
    body: "'Open Sans',Helvetica,Helvetica Neue,Arial,sans-serif,sans-serif",
    headings: "'FS Joey',Helvetica,Helvetica Neue,Arial,sans-serif,sans-serif",
    nav: "'FS Joey',Helvetica,Helvetica Neue,Arial,sans-serif,sans-serif",
  },
  space,
  transitionDuration: '0.25s',
  richtext: 'simple',
  borderRadius: true,
}

const theme = {
  blue: COH,
  black: anetwork,
  green: socialIndex,
}

export const zIndices = {
  modal: 9000,
  navigation: 1000,
}

export const transitionDuration = {
  default: '0.25s',
}

export default theme

export const sizes = {
  xsmall: { min: 0, max: 574 },
  small: { min: 575, max: 764 },
  medium: { min: 765, max: 991 },
  large: { min: 992, max: 1180 },
  xlarge: { min: 1181, max: Infinity },
}
