import { useEffect, useState } from 'react'

const useWindowReady = () => {
  const [windowReady, setWindowReady] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setWindowReady(true)
    }
  }, [])

  return windowReady
}

export default useWindowReady
