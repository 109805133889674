import styled from 'styled-components'
import theme, { sizes } from '../../themes/index'
import {
  space,
  color,
  border,
  flexbox,
  typography,
  position,
  layout,
} from 'styled-system'
import { A } from '../Button'

// Containers
export const StyledButtonContainer = styled.div`
  ${flexbox}
  ${layout}
  ${space}
`

export const StyledFooterWrapper = styled.footer`
  ${position}
  ${space}
  ${color}
`

export const StyledFooter = styled.div`
  max-width: ${sizes.large.max}px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  ${typography}
  ${color}
  ${space}
`

export const StyledList = styled.ul`
  padding: 0;
  display: flex;
  flex-flow: wrap;
  ${position}
  ${flexbox}
  ${layout}
  ${color}
  ${typography}
  ${space}
  ${flexbox}

  li:last-child a span {
    border-right: none;
  }
`
export const StyledListItem = styled.li`
  list-style: none;
  display: flex;
  flex-flow: wrap;
  ${layout}
  ${color}
  ${typography}
  ${space}
  ${flexbox}
`

export const StyledNavigationIcon = styled.li`
  display: inline-flex;
  text-align: center;
  @media print, screen and (min-width: 64em) {
    margin-top: 0;
  }
  transition: opacity ${theme.transitionDuration};
  ${position}
  ${space}
  ${flexbox}
  ${layout}
  ${color}
`

// Links
export const StyledAnchor = styled.a`
  border-bottom: 2px solid transparent;
  height: fit-content;
  ${({ hasLink }) => !hasLink && `pointer-events:none; opacity: 0.5;`}
  ${color}
  ${layout}
  ${typography}
  ${border}
  ${space}
  
  // &:hover {
  //   border-bottom: 2px solid ${({ hoverBorder }) => hoverBorder};
  // }
`

export const StyledLink = styled(A)`
  border-bottom: 2px solid transparent;
  height: fit-content;
  display: block;
  ${color}
  ${typography}
  ${border}
  ${space}

  // &:hover {
  //   ${({ hoverBorder }) => `border-bottom: 2px solid ${hoverBorder}`};
  // }

`

export const StyledNavigationLink = styled(A)`
  color: currentColor;
  max-width: ${sizes.large.max}px;
  transition: all ${theme.transitionDuration};
  ${space}

  &:hover {
    ${({ hoverBgChange }) =>
      hoverBgChange && `background-color: ${theme.colors.blues[1]}`};
    ${({ hoverGrowText }) => hoverGrowText && `transform: scale(1.1);`}
  }
`

export const StyledCopyrightText = styled.p`
  margin: 0;
  padding: 0;
  transition: color 0.25s;
  ${border}
  ${position}
  ${layout}
  ${typography}
  ${space}
  ${color}
`

export const StyledSocialLink = styled.a`
  ${space}
`

export const StyledIcon = styled.img`
  ${layout}
  ${space}
`

export const StyledMediaIcon = styled(StyledIcon)`
  height: auto;
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
  @keyframes filter-animation {
    25% {
      filter: sepia(100%) invert(100%) brightness(2.1);
    }
    50% {
      filter: sepia(100%) invert(100%) brightness(2.5);
    }
    75% {
      filter: sepia(100%) invert(100%) brightness(2.5);
    }
    100% {
      filter: sepia(100%) invert(100%) brightness(2.5);
    }
  }

  &:hover {
    animation: filter-animation 1.75s;
    animation-fill-mode: forwards;
  }
`

export const StyledSvgContainer = styled.div`
  ${layout}
`

export const StyledSvg = styled.img`
  ${layout}
  ${space}
  ${color}
`

export const StyledDiv = styled.div`
  ${layout}
  ${space}
`
