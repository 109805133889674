import React from 'react'
import PropTypes from 'prop-types'
import { StyledList, StyledLink, StyledListItem, StyledAnchor } from '../styles'
import { categorize } from '../../../utils'
import { useHover } from '../../../hooks'

const LinkWrapper = ({ to, href, children, theme, hoverBorder, ...rest }) => {
  const [hoverRefSlug, isHoveredSlug] = useHover()
  const [hoverRefUrl, isHoveredUrl] = useHover()
  if (to) {
    return (
      <div ref={hoverRefSlug} style={{ height: 'fit-content' }}>
        <StyledLink
          to={to === 'home' ? '/' : to}
          py={[1, 1, null]}
          my={[1, 1, null]}
          hasLink={to}
          borderColor={hoverBorder && isHoveredSlug && `${theme}.border`}
          {...rest}
        >
          {children}
        </StyledLink>
      </div>
    )
  } else {
    return (
      <StyledAnchor
        ref={hoverRefUrl}
        href={href}
        target="__blank"
        rel="noopener noreferrer"
        py={[1, 1, null]}
        my={[1, 1, null]}
        hasLink={href}
        borderColor={hoverBorder && isHoveredUrl && `${theme}.border`}
        {...rest}
      >
        {children}
      </StyledAnchor>
    )
  }
}

const FooterLinks = ({
  footerLinks = [],
  groupByCategory = false,
  theme,
  hoverBorder = false,
  fontSize,
}) => {
  if (!footerLinks.length) return null
  const { categories, categoryMap } = categorize(footerLinks, 'category')

  if (groupByCategory && categories.length) {
    if (categories.length === 1) {
      const links = categoryMap[categories[0]]
      return (
        <StyledListItem>
          <StyledList>
            {links.map(
              ({ navigationLink: { id, url, slug, displayText } }, i) => (
                <StyledListItem
                  key={`${id}-${i}`}
                  textAlign="start"
                  p={3}
                  fontSize={fontSize}
                >
                  <LinkWrapper
                    to={slug}
                    href={url}
                    hoverBorder={hoverBorder}
                    theme={theme}
                  >
                    {displayText}
                  </LinkWrapper>
                </StyledListItem>
              )
            )}
          </StyledList>
        </StyledListItem>
      )
    }

    return (
      <StyledListItem>
        <StyledList
          maxWidth="maxWidth.medium"
          flexDirection={['column', 'row', 'row']}
          justifyContent="center"
        >
          {categories.map((c, i) => {
            return (
              <StyledListItem
                key={i}
                width={[1, categoryMap[c].length > 1 ? 3 / 4 : 1 / 4]}
                justifyContent="space-between"
                flexDirection={['row', 'row']}
                my={3}
              >
                <StyledList>
                  {categoryMap[c].map(
                    ({ displayText, navigationLink: { id, url, slug } }, i) => (
                      <StyledListItem
                        key={`${id}-${i}`}
                        width={[
                          categoryMap[c].length > 1 ? 1 / 2 : 1,
                          categoryMap[c].length > 1 ? 1 / 3 : 1,
                        ]}
                        textAlign="start"
                        fontSize={fontSize}
                      >
                        <LinkWrapper
                          to={slug}
                          href={url}
                          hoverBorder={hoverBorder}
                          theme={theme}
                        >
                          {displayText}
                        </LinkWrapper>
                      </StyledListItem>
                    )
                  )}
                </StyledList>
              </StyledListItem>
            )
          })}
        </StyledList>
      </StyledListItem>
    )
  }

  return (
    <StyledList
      flexDirection={['column', 'column', 'row']}
      py={[3, 3, 0]}
      justifyContent="center"
      alignItems="center"
    >
      {footerLinks.map(
        ({ navigationLink: { id, slug, url, displayText } }, i) => (
          <StyledListItem key={`${id}-${i}`}>
            <LinkWrapper
              to={slug}
              href={url}
              px={3}
              py={[1, 1, 0]}
              borderRight={['none', 'none', '1px solid currentcolor']}
              hoverBorder={hoverBorder}
              fontSize={fontSize}
              bg={`${theme}.bg`}
              color={`${theme}.text`}
              textAlign="center"
              fontWeight="100"
            >
              {displayText}
            </LinkWrapper>
          </StyledListItem>
        )
      )}
    </StyledList>
  )
}

FooterLinks.propTypes = {
  footerLinks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      displayText: PropTypes.string,
      navigationLink: PropTypes.shape({
        id: PropTypes.string,
        url: PropTypes.string,
        slug: PropTypes.slug,
        displayText: PropTypes.string,
      }),
    })
  ),
  groupByCategory: PropTypes.bool,
  theme: PropTypes.string,
  hoverBorder: PropTypes.bool,
  fontSize: PropTypes.number,
}
export default FooterLinks
