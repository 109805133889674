import React from 'react'
import { StyledButtonContainer } from '../styles'
import { TopButton } from '../../Button/index'

const FooterButton = ({ theme = 'dark' }) => {
  return (
    <StyledButtonContainer
      display="flex"
      width="100%"
      justifyContent={['flex-end', 'flex-end', 'center']}
      my={4}
    >
      <TopButton
        theme={theme}
        ml={0}
        display="block"
        fontSize={9}
        fontWeight="300"
      />
    </StyledButtonContainer>
  )
}

export default FooterButton
