import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

const TitleAndMetaTags = ({
  pageSEO = true,
  articleSEO = false,
  metaTitle,
  metaDescription,
  ogImage,
  lang = 'en-US',
  slug,
  config,
}) => {
  let {
    url,
    twitterHandle,
    image,
    imageWidth,
    imageHeight,
    description,
    titleAlt,
    title,
  } = config
  image = ogImage
  title = metaTitle
  description = metaDescription
  url = slug ? `${url}/${slug}/` : `${url}/`

  // schema - default || content type = page
  const schemaOrgJSONLD = [
    {
      '@context': 'http://schema.org',
      '@type': pageSEO ? 'WebPage' : 'WebSite',
      url: url,
      name: title,
      alternateName: titleAlt,
    },
  ]

  // schema - content type - post
  if (articleSEO) {
    schemaOrgJSONLD.push(
      {
        '@context': 'http://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            item: {
              '@id': config.siteUrl,
              name: config.siteTitle,
            },
          },
          {
            '@type': 'ListItem',
            position: 2,
            item: {
              '@id': url,
              name: title,
            },
          },
        ],
      },
      {
        '@context': 'http://schema.org',
        '@type': 'BlogPosting',
        url: url,
        name: title,
        alternateName: titleAlt,
        headline: title,
        image: {
          '@type': 'ImageObject',
          url: image,
          width: imageWidth,
          height: imageHeight,
        },
        author: {
          '@type': 'Person',
          name: config.author,
          url: config.authorUrl,
        },
        publisher: {
          '@type': 'Organization',
          name: config.publisher,
          url: config.siteUrl,
        },
        datePublished: '1/1/21',
        mainEntityOfPage: url,
      }
    )
  }

  return (
    <Helmet htmlAttributes={{ lang }}>
      {/* General tags */}
      <title>{title}</title>
      <meta name="image" content={image} />
      <meta name="description" content={description} />

      {/* Schema.org tags */}
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>

      {/* OpenGraph tags */}
      <meta property="og:title" content={title} />
      <meta property="og:type" content={articleSEO ? 'article' : 'website'} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:image:width" content={imageWidth} />
      <meta property="og:image:height" content={imageHeight} />

      <meta property="og:url" content={url} />

      {/* Social tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={twitterHandle} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:description" content={description} />

      {/* Full Screen Capabilities */}
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta
        name="apple-mobile-web-app-status-bar-style"
        content="black-translucent"
      />
    </Helmet>
  )
}

TitleAndMetaTags.propTypes = {
  pageSEO: PropTypes.bool,
  articleSEO: PropTypes.bool,
  metaTitle: PropTypes.string,
  metaDescription: PropTypes.string,
  ogImage: PropTypes.string,
  lang: PropTypes.string,
  slug: PropTypes.string,
  config: PropTypes.exact({
    url: PropTypes.string,
    twitterHandle: PropTypes.string,
    image: PropTypes.string,
    imageWidth: PropTypes.number,
    imageHeight: PropTypes.number,
    description: PropTypes.string,
    titleAlt: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
}

export default TitleAndMetaTags
