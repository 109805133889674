import React from 'react'
import PropTypes from 'prop-types'
import FooterSocial from './FooterSocial'
import FooterSimple from './FooterSimple'
import FooterSingleLine from './SingleLine/FooterSingleLine'

const Footer = ({ type = 'simple', ...rest }) => {
  switch (type) {
    case 'social':
      return <FooterSocial {...rest} />
    case 'single-line':
      return <FooterSingleLine {...rest} />
    default:
      return <FooterSimple {...rest} />
  }
}

Footer.propTypes = {
  type: PropTypes.string,
}

export default Footer
