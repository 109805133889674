import React from 'react'
import PropTypes from 'prop-types'
import { StyledFooterWrapper } from '../styles'
import SingleLineCopyright from './SingleLineCopyright'
import { useComponentTheme } from '../../../hooks'

const FooterSingleLine = ({
  theme: themeBool = true,
  footerLinks = [],
  copyright,
}) => {
  const { theme } = useComponentTheme(themeBool)
  return (
    <StyledFooterWrapper
      data-testid="footer-single-line"
      bg={`${theme}.bg`}
      color={`${theme}.text`}
    >
      <SingleLineCopyright
        copyright={copyright}
        footerLinks={footerLinks}
        theme={theme}
      />
    </StyledFooterWrapper>
  )
}

FooterSingleLine.propTypes = {
  theme: PropTypes.bool,
  footerLinks: PropTypes.arrayOf(PropTypes.object),
  copyright: PropTypes.arrayOf(PropTypes.object),
}

export default FooterSingleLine
