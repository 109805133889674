import React from 'react'
import PropTypes from 'prop-types'
import { StyledList, StyledCopyrightText, StyledListItem } from '../styles'
import FooterLogo from '../components/FooterLogo'
import useMobile from '../../../hooks/useMobile'
import SingleLineLink from './SingleLineLink'

const SingleLineCopyright = ({ copyright, footerLinks = [], theme }) => {
  const [isMobile] = useMobile()
  if (!copyright) return null

  return (
    <StyledList
      flexDirection={isMobile ? 'column' : 'row'}
      m={isMobile ? '0 auto' : '0'}
      maxWidth="100%"
      justifyContent="space-between"
      alignItems="center"
      bg={`${theme}.bg`}
      color={`${theme}.text`}
      pl={4}
      py={2}
    >
      <FooterLogo mb="0" svgWidth="150px" />
      {copyright.map(({ id, displayText }) => (
        <StyledListItem
          my={[2, 2, 1]}
          justifyContent="center"
          alignItems="center"
          key={id}
        >
          <StyledCopyrightText
            px={2}
            fontSize={6}
            fontWeight={100}
            textAlign="center"
          >
            {displayText}
          </StyledCopyrightText>
          {footerLinks.map(({ id, ...rest }) => (
            <SingleLineLink key={id} theme={theme} {...rest} />
          ))}
        </StyledListItem>
      ))}
      {!isMobile && <FooterLogo mb="0" svgWidth="150px" opacity={0} />}
    </StyledList>
  )
}

SingleLineCopyright.propTypes = {
  copyright: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      displayText: PropTypes.string,
    })
  ),
  bg: PropTypes.string,
  color: PropTypes.string,
  footerLinks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
}

export default SingleLineCopyright
