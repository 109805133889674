import fb from '../../../assets/fb-icon@3x.png'
import ig from '../../../assets/ig-icon@3x.png'
import tw from '../../../assets/tw-icon@3x.png'

export default [
  {
    "id": "1",
    "url": "https://www.facebook.com/cityofhope/",
    "src": fb,
    "alt": "fb"
  },
  {
    "id": "2",
    "url": "https://www.instagram.com/cityofhope/?hl=en",
    "src": ig,
    "alt": "ig"
  },
  {
    "id": "3",
    "url": "https://twitter.com/cityofhope?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor",
    "src": tw,
    "alt": "tw"
  }
]