import React from 'react'
import PropTypes from 'prop-types'
import { StyledList, StyledIcon, StyledListItem } from '../styles'

const FooterIcons = ({ footerIcons }) => {
  if (!footerIcons) return null
  return (
    <StyledList
      flexDirection={'row'}
      my={3}
      paddingLeft="60px"
      justifyContent="center"
      alignItems="center"
    >
      {footerIcons.map(({ id, src, alt }) => (
        <StyledListItem key={id} my={0}>
          <StyledIcon
            src={src}
            alt={alt}
            height={['60px', 'auto', 'auto']}
            width={['auto', '100%', '100%']}
            my={[0, 0, 4]}
            mx={[2, 3, 4]}
          />
        </StyledListItem>
      ))}
    </StyledList>
  )
}

FooterIcons.propTypes = {
  footerIcons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      src: PropTypes.string,
      alt: PropTypes.string,
    })
  ),
}

export default FooterIcons
