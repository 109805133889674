import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledNavigationItem,
  StyledNavigationLink,
  StyledNavigationAnchor,
  Cell,
  StyledText,
} from '../styles'
import SubNav from '../SubNavigation'
import { navigate } from 'gatsby'

const stringToArrayAtNavigationCharacters = (
  string,
  character,
  secondCharacter
) => {
  if (!string.includes(character)) {
    return [string]
  }
  if (!string.includes(secondCharacter)) {
    return string.split(character)
  } else {
    let splitArr = string.split(secondCharacter)

    return [splitArr[0] + ' &', splitArr[1]]
  }
}

const renderNavDisplayText = (arrayOfStrings, textAlign) => {
  if (arrayOfStrings.length < 1) return <div />
  if (arrayOfStrings.length === 1) {
    return <span>{arrayOfStrings[0]}</span>
  } else {
    return (
      <StyledText textAlign={textAlign}>
        <Cell letterSpacing=".75px">{arrayOfStrings[0]}</Cell>
        <br />
        {arrayOfStrings.slice(1, arrayOfStrings.length).map(word => (
          <Cell fontWeight={400} letterSpacing=".75px" key={word}>
            {word}
          </Cell>
        ))}
      </StyledText>
    )
  }
}

const formatAndRenderDisplayText = (string, textAlign) => {
  if (!string) return null
  let splitDisplayText = stringToArrayAtNavigationCharacters(string, ' ', ' & ')
  return renderNavDisplayText(splitDisplayText, textAlign)
}

const NavigationItemMobile = ({
  __typename,
  displayText,
  id,
  url,
  slug,
  subNav,
  theme = 'light',
  uncategorized = false,
  stackText = false,
  parentSlug,
  navbarOffsetTop,
  openOverlay,
  closeOverlay,
  toggledSubNav,
  color,
  fontSize,
  preview,
  ml,
}) => {
  if (!displayText) return null

  const openSubNav = () => {
    if (subNav) {
      openOverlay()
    }
  }

  const closeSubNav = () => {
    if (subNav) {
      closeOverlay()
    }
  }

  const handleClick = id => {
    closeOverlay()
    navigate(`/#${id}`, {
      state: { id },
    })
  }

  switch (__typename) {
    case 'ContentfulProject':
    case 'ContentfulPage':
      return (
        <>
          <StyledNavigationItem>
            <StyledNavigationLink
              to={subNav ? null : parentSlug ? `${parentSlug}/${slug}` : slug}
              onClick={openSubNav}
              p={3}
              ml={ml}
              pl={uncategorized ? 0 : 3}
              color={color || `${theme}.text`}
              fontSize={fontSize || (stackText && 5)}
              hoverGrowText
              transitionDuration={theme.transitionDuration}
              textTransform={stackText && 'uppercase'}
            >
              {stackText
                ? formatAndRenderDisplayText(displayText, 'left')
                : displayText}
            </StyledNavigationLink>
          </StyledNavigationItem>
          {subNav && (
            <StyledNavigationItem>
              <SubNav
                subNav={subNav}
                theme={theme}
                isMobile
                parentSlug={preview ? '' : slug}
                toggledSubNav={toggledSubNav}
                navbarOffsetTop={navbarOffsetTop}
                closeOverlay={closeSubNav}
              />
            </StyledNavigationItem>
          )}
        </>
      )
    case 'ContentfulCompany':
      return (
        <StyledNavigationItem fontWeight={600}>
          <StyledNavigationLink
            to={`/#${id}`}
            onClick={() => handleClick(id)}
            p={3}
            ml={ml}
            pl={uncategorized ? 0 : 3}
            color={color || `${theme}.text`}
            fontSize={fontSize || (stackText && 5)}
            hoverGrowText
            transitionDuration={theme.transitionDuration}
            textTransform={stackText && 'uppercase'}
          >
            {stackText
              ? formatAndRenderDisplayText(displayText, 'left')
              : displayText}
          </StyledNavigationLink>
        </StyledNavigationItem>
      )
    case 'ContentfulExternalLink':
      return (
        <StyledNavigationItem>
          <StyledNavigationAnchor
            href={url}
            target="__blank"
            rel="noopener noreferrer"
            onClick={openSubNav}
            p={3}
            ml={ml}
            pl={uncategorized ? 0 : 3}
            color={color || `${theme}.text`}
            fontSize={fontSize || (stackText && 5)}
            hoverGrowText
            transitionDuration={theme.transitionDuration}
            textTransform={stackText && 'uppercase'}
          >
            {stackText
              ? formatAndRenderDisplayText(displayText, 'left')
              : displayText}
          </StyledNavigationAnchor>
        </StyledNavigationItem>
      )
    default:
      return null
  }
}

NavigationItemMobile.propTypes = {
  __typename: PropTypes.string,
  displayText: PropTypes.string,
  id: PropTypes.string,
  url: PropTypes.string,
  slug: PropTypes.string,
  subNav: PropTypes.object,
  theme: PropTypes.string,
  uncategorized: PropTypes.bool,
  stackText: PropTypes.bool,
  parentSlug: PropTypes.string,
  navbarOffsetTop: PropTypes.string,
  openOverlay: PropTypes.func,
  closeOverlay: PropTypes.func,
  toggledSubNav: PropTypes.bool,
  color: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  fontSize: PropTypes.number,
  preview: PropTypes.bool,
  ml: PropTypes.number,
}

export default NavigationItemMobile
