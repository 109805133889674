import React from 'react'
import PropTypes from 'prop-types'
import SubNavDesktop from './SubNavigationDesktop'
import SubNavMobile from './SubNavigationMobile'

const SubNavigation = ({ isMobile, ...rest }) => {
  if (isMobile) {
    return <SubNavMobile {...rest} />
  }
  return <SubNavDesktop {...rest} />
}

SubNavigation.propTypes = {
  isMobile: PropTypes.bool,
}

export default SubNavigation
