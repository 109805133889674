import React from 'react'
import PropTypes from 'prop-types'
import { StyledFooterWrapper, StyledFooter } from './styles'
import { useMobile, useComponentTheme } from '../../hooks'
import FooterIcons from './components/FooterIcons'
import FooterLinks from './components/FooterLinks'
import FooterCopyright from './components/FooterCopyright'
import FooterSocialMedia from './components/FooterSocialMedia'
import mockSocialMediaLinks from './__mocks__/socialMediaLinks.js'
import mockFooterIcons from './__mocks__/footerIcons.js'
import FooterButton from './components/FooterButton'

const FooterSocial = ({
  theme: themeBool = true,
  footerLinks = [],
  copyright = [],
}) => {
  const [isMobile] = useMobile()
  const { theme } = useComponentTheme(themeBool)
  return (
    <StyledFooterWrapper
      data-testid="footer-social"
      bg={`${theme}.bg`}
      color={`${theme}.text`}
    >
      <StyledFooter p={3} pb={5} fontSize={isMobile ? 8 : 10}>
        {isMobile && <FooterButton />}
        <FooterIcons footerIcons={mockFooterIcons} />
        <FooterLinks footerLinks={footerLinks} theme={theme} />
        <FooterCopyright copyright={copyright} />
        <FooterSocialMedia
          socialMediaLinks={mockSocialMediaLinks}
          maxWidth={isMobile ? '44px' : '35px'}
        />
      </StyledFooter>
    </StyledFooterWrapper>
  )
}

FooterSocial.propTypes = {
  theme: PropTypes.bool,
  footerLinks: PropTypes.arrayOf(PropTypes.object),
  copyright: PropTypes.arrayOf(PropTypes.object),
}

export default FooterSocial
