import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledNavigationIcon,
  StyledNavigationLink,
  StyledSvg,
  StyledSvgContainer,
} from '../styles'

const FooterLogo = ({ logo, alt, mb, svgWidth = '228px', ...rest }) => {
  if (!logo) return null

  return (
    <StyledNavigationIcon py={3} mb={mb || '1.2rem'} {...rest}>
      <StyledNavigationLink to="/">
        <StyledSvgContainer maxWidth="maxWidth.small">
          <StyledSvg src={logo} alt={alt} width={svgWidth} height="auto" />
        </StyledSvgContainer>
      </StyledNavigationLink>
    </StyledNavigationIcon>
  )
}

FooterLogo.propTypes = {
  logo: PropTypes.string,
  alt: PropTypes.string,
  mb: PropTypes.string,
  svgWidth: PropTypes.string,
}

export default FooterLogo
