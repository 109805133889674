import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledNavigationItem,
  StyledNavigationLink,
  StyledNavigationAnchor,
  Cell,
  StyledText,
} from '../styles'
import SubNav from '../SubNavigation'
import { useHover } from '../../../hooks'
import { transitionDuration } from '../../../themes'

const stringToArrayAtNavigationCharacters = (
  string,
  character,
  secondCharacter
) => {
  if (!string.includes(character)) {
    return [string]
  }
  if (!string.includes(secondCharacter)) {
    return string.split(character)
  } else {
    let splitArr = string.split(secondCharacter)
    return [splitArr[0] + ' &', splitArr[1]]
  }
}

const renderNavDisplayText = (arrayOfStrings, textAlign) => {
  if (arrayOfStrings.length < 1) return <div />
  if (arrayOfStrings.length === 1) {
    return <span>{arrayOfStrings[0]}</span>
  } else {
    return (
      <StyledText textAlign={textAlign}>
        <Cell letterSpacing=".75px">{arrayOfStrings[0]}</Cell>
        <br />
        {arrayOfStrings.slice(1, arrayOfStrings.length).map(word => (
          <Cell fontWeight={400} letterSpacing=".75px" key={word}>
            {word}
          </Cell>
        ))}
      </StyledText>
    )
  }
}

const formatAndRenderDisplayText = (string, textAlign) => {
  if (!string) return null
  let splitDisplayText = stringToArrayAtNavigationCharacters(string, ' ', ' & ')
  return renderNavDisplayText(splitDisplayText, textAlign)
}

const NavigationItem = ({
  __typename,
  displayText,
  url,
  slug,
  subNav,
  theme = 'light',
  stackText = false,
  parentSlug,
  isActive,
  preview,
  activeColor = 'blues.4',
  changeTextColorOnHover,
  changeBgOnHover,
  fontWeight,
  fontSize,
}) => {
  const [hoverRef, isHovered, setHover] = useHover()

  if (!displayText) return null

  switch (__typename) {
    case 'ContentfulProject':
    case 'ContentfulPage':
      return (
        <>
          <StyledNavigationItem ref={hoverRef}>
            <StyledNavigationLink
              p={3}
              color={
                (isActive && activeColor) ||
                (changeTextColorOnHover && isHovered && 'dark.textAlt')
              }
              bg={
                (isActive || (changeBgOnHover && isHovered)) && `${theme}.bgAlt`
              }
              transitionDuration={transitionDuration.default}
              textTransform={stackText && 'uppercase'}
              to={parentSlug ? `${parentSlug}/${slug}` : slug}
              fontWeight={fontWeight}
              fontSize={fontSize}
            >
              {stackText
                ? formatAndRenderDisplayText(displayText, 'center')
                : displayText}
            </StyledNavigationLink>
          </StyledNavigationItem>
          {subNav && (
            <SubNav
              setHover={setHover}
              isHoveredParent={isHovered}
              subNav={subNav}
              theme={theme}
              parentSlug={preview ? '' : slug}
            />
          )}
        </>
      )
    case 'ContentfulExternalLink':
      return (
        <StyledNavigationItem>
          <StyledNavigationAnchor
            p={3}
            color={isActive && activeColor}
            bg={(isActive || isHovered) && `${theme}.bgAlt`}
            transitionDuration={transitionDuration.default}
            textTransform={stackText && 'uppercase'}
            href={url}
            target="__blank"
            rel="noopener noreferrer"
          >
            {stackText
              ? formatAndRenderDisplayText(displayText, 'center')
              : displayText}
          </StyledNavigationAnchor>
        </StyledNavigationItem>
      )
    default:
      return null
  }
}

NavigationItem.propTypes = {
  __typename: PropTypes.string,
  displayText: PropTypes.string,
  id: PropTypes.string,
  url: PropTypes.string,
  slug: PropTypes.string,
  subNav: PropTypes.object,
  theme: PropTypes.string,
  stackText: PropTypes.bool,
  parentSlug: PropTypes.string,
  isActive: PropTypes.bool,
  preview: PropTypes.bool,
  activeColor: PropTypes.string,
  changeTextColorOnHover: PropTypes.bool,
  changeBgOnHover: PropTypes.bool,
  fontWeight: PropTypes.number,
  fontSize: PropTypes.number,
}

export default NavigationItem
