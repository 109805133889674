import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { StyledNavWrapperMobile } from '../styles'
import useWindowScrollPosition from '../../../hooks/useWindowScrollPosition'
import { zIndices } from '../../../themes'

const NavigationWrapper = ({
  children,
  stickyDelay = false,
  theme = 'light',
  transparentBg = false,
  ...rest
}) => {
  const [shrink, setShrink] = useState({ val: false, offset: 0 })
  const { y } = useWindowScrollPosition()

  useEffect(() => {
    if (!y || y < 60) {
      setShrink({ val: false, offset: 0 })
    }
    if (y - shrink.offset > 60) {
      setShrink({ val: true, offset: y })
    }
    if (y - shrink.offset < -60) {
      setShrink({ val: false, offset: y })
    }
  }, [setShrink, shrink.offset, y])

  if (stickyDelay) {
    return (
      <StyledNavWrapperMobile
        role="navigation"
        bg={transparentBg ? 'transparent' : `${theme}.bg`}
        color={`${theme}.text`}
        shrink={shrink.val}
        position="fixed"
        zIndex={zIndices.navigation}
        {...rest}
      >
        {children}
      </StyledNavWrapperMobile>
    )
  }

  return (
    <StyledNavWrapperMobile
      role="navigation"
      bg={transparentBg ? 'transparent' : `${theme}.bg`}
      color={`${theme}.text`}
      position="fixed"
      zIndex={zIndices.navigation}
      {...rest}
    >
      {children}
    </StyledNavWrapperMobile>
  )
}

NavigationWrapper.propTypes = {
  stickyDelay: PropTypes.bool,
  theme: PropTypes.string,
  transparentBg: PropTypes.bool,
}
export default NavigationWrapper
