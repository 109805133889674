import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Footer as MainFooter } from '../../../../common/components/Footer'
import logo from '../../assets/adotnetwork-logo.svg'

const Footer = () => {
  const { footer } = useStaticQuery(graphql`
    query FooterQuery {
      footer: allContentfulNavigation(filter: { placement: { eq: "Footer" } }) {
        nodes {
          navItems {
            ... on ContentfulNavigationItem {
              id
              displayText
              category
              navigationLink {
                ... on ContentfulCompany {
                  __typename
                  id: contentful_id
                  url: website
                  displayText: title
                }
                ... on ContentfulExternalLink {
                  __typename
                  url
                  displayText: internalTitle
                }
                ... on ContentfulPage {
                  __typename
                  id: contentful_id
                  slug
                  displayText: title
                }
              }
            }
          }
        }
      }
    }
  `)

  const year = new Date().getFullYear()
  const copyright = [
    {
      id: '1',
      displayText: `© ${year} a.network. All rights reserved.`,
    },
  ]

  const footerLinks = footer.nodes?.[0]?.navItems
  return (
    <MainFooter
      logo={logo}
      alt="anetwork"
      footerLinks={footerLinks}
      copyright={copyright}
      theme={false}
    />
  )
}

export default Footer
