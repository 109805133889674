import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHover } from '../../../hooks'
import {
  DropDownMenu,
  DropDownNavItem,
  DropDownNavContainer,
  DropDownNavLink,
  SlidingUnderline,
} from '../styles'

const SubNavDesktop = ({
  subNav: { navItems },
  subNavPage = false,
  parentSlug,
}) => {
  return (
    <DropDownNavContainer>
      {navItems.map(
        ({ displayText, id, navigationLink: { slug, id: linkId } }, i) => (
          <DropDownNavItem
            key={id}
            fontWeight={600}
            textAlign="center"
            fontFamily="headings"
            index={i}
            navItemsLength={navItems.length}
            subNavPage={subNavPage}
          >
            <DropDownNavLink
              to={`${parentSlug}/${slug}`}
              navItemsLength={navItems.length}
            >
              {displayText}
            </DropDownNavLink>
          </DropDownNavItem>
        )
      )}
      <SlidingUnderline navItemsLength={navItems.length} />
    </DropDownNavContainer>
  )
}

const SubNavHoverContainer = ({
  setHover,
  isHoveredParent,
  subNav,
  parentSlug,
}) => {
  const [hoverRefDropDown, isHoveredChild] = useHover()

  useEffect(() => {
    setHover(isHoveredChild)
  }, [isHoveredChild, setHover])

  if (!subNav) return null
  return (
    <DropDownMenu
      ref={hoverRefDropDown}
      visible={isHoveredParent || isHoveredChild}
    >
      <SubNavDesktop subNav={subNav} parentSlug={parentSlug} />
    </DropDownMenu>
  )
}

SubNavHoverContainer.propTypes = {
  isHoveredParent: PropTypes.bool.isRequired,
  setHover: PropTypes.func,
  subNav: PropTypes.object,
  parentSlug: PropTypes.string,
}

export default SubNavHoverContainer
