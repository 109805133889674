import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledMenuBtnContainer,
  StyledMenuBtnLine,
  StyledBtnBox,
} from '../styles'

const NavigationMenuBtn = ({
  handleClick,
  toggled,
  theme,
  large = false,
  order,
}) => {
  if (large) {
    return (
      <StyledMenuBtnContainer
        data-testid="menu-btn"
        onClick={handleClick}
        py={3}
        px={3}
        m="2px"
        order={order}
      >
        <StyledBtnBox width="30px" height="30px">
          <StyledMenuBtnLine
            width="30px"
            height="3px"
            toggled={toggled}
            bg={`${theme}.text`}
            large
          />
        </StyledBtnBox>
      </StyledMenuBtnContainer>
    )
  }

  return (
    <StyledMenuBtnContainer
      data-testid="menu-btn"
      onClick={handleClick}
      p={2}
      m={3}
      order={order}
    >
      <StyledBtnBox width="20px" height="24px">
        <StyledMenuBtnLine
          width="20px"
          toggled={toggled}
          bg={`${theme}.text`}
        />
      </StyledBtnBox>
    </StyledMenuBtnContainer>
  )
}

NavigationMenuBtn.propTypes = {
  handleClick: PropTypes.func.isRequired,
  toggled: PropTypes.bool.isRequired,
  theme: PropTypes.string,
  large: PropTypes.bool,
  order: PropTypes.number,
}
export default NavigationMenuBtn
