import React from 'react'
import {
  StyledNotification,
  StyledNotificationIcon,
  StyledText,
  StyledNotificationContent,
  StyledContainer,
} from '../styles'
import notificationIcon from '../../../assets/AlertButton.svg'

const Notification = () => {
  return (
    <StyledContainer mr={3}>
      <StyledNotification
        opacity={0.95}
        zIndex={1001}
        to="/map"
        py={1}
        px={3}
        minWidth="200px"
        theme="dark"
        borderWidth={1}
      >
        <StyledNotificationContent
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <StyledNotificationIcon src={notificationIcon} alt="notification" />
          <StyledText
            height="100%"
            margin="auto 0"
            fontSize={10}
            fontWeight={500}
            color="#0467BA"
          >
            Parking Updates
          </StyledText>
        </StyledNotificationContent>
      </StyledNotification>
    </StyledContainer>
  )
}

export default Notification
