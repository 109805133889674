import {
  useState,
  useEffect,
  useContext,
  useReducer,
  useCallback,
  useMemo,
  useRef,
  useImperativeHandle,
  useLayoutEffect,
  useDebugValue,
} from 'react'
import createPersistedGlobalState from './useGlobalState'

import useDarkMode from './useDarkMode.js'
import useMobile from './useMobile'
import useComponentTheme from './useComponentTheme.js'
import useHover from './useHover.js'
import usePreviewQuery from './usePreviewQuery.js'
import useWindowReady from './useWindowReady'
import useAcceptedTermsState from './useAcceptedTermsState'
import useLockBodyScroll from './useLockBodyScroll'
import useFullScreen from './useFullScreen'
export const useVisitedState = createPersistedGlobalState('visited')

export const useSubNavOpenState = createPersistedGlobalState('open')
export const useActiveProjectIdState = createPersistedGlobalState(
  'activeProjectId'
)

export const useSubNavIndexState = createPersistedGlobalState('subNavIndex')

export {
  useDarkMode,
  useComponentTheme,
  useState,
  useEffect,
  useContext,
  useReducer,
  useCallback,
  useMemo,
  useRef,
  useImperativeHandle,
  useLayoutEffect,
  useDebugValue,
  useMobile,
  useHover,
  usePreviewQuery,
  useWindowReady,
  useAcceptedTermsState,
  useLockBodyScroll,
  useFullScreen,
}
