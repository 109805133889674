import styled, { keyframes } from 'styled-components'

import {
  position,
  flexbox,
  space,
  color,
  layout,
  typography,
  border,
  shadow,
} from 'styled-system'

const BaseButton = styled.button`
${color}
${layout}
${typography}
${border}
${space}
${shadow}
${position}
${flexbox}

`
export const StyledTopTextContainer = styled.div`
  ${color}
  ${layout}
  ${typography}
  ${border}
  ${space}
  ${shadow}
  ${position}
  ${flexbox}
`

export const StyledTopText = styled.p`
  ${color}
  ${layout}
  ${typography}
  ${border}
  ${space}
  ${shadow}
  ${position}
  ${flexbox}
`

export const StyledButton = styled(BaseButton)`
  text-decoration: none;
  outline: none;
  min-width: ${({ minWidth }) => minWidth || '226px'};
  border-style: solid;
  cursor: pointer;
  letter-spacing: 0.87px;
  @media (max-width: 500px) {
    max-width: 246px;
    min-width: 0px;
  }
  &:hover {
    background: ${({ hover }) => hover || 'transparent'};
    opacity: ${({ hoverOpacity }) => hoverOpacity || 1};
  }
`

export const NakedA = styled.span`
  text-decoration: none !important;
  outline: none;
  ${color}
  ${layout}
  ${typography}
  ${border}
  ${space}
  ${shadow}
  ${position}
  ${flexbox}

`

export const StyledIconButton = styled(BaseButton)`
  text-decoration: none;
  outline: none;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border-style: solid;
`

export const StyledScrollButton = styled(BaseButton)`
  box-sizing: border-box;
  text-decoration: none;
  outline: none;
  height: 60px;
  border-radius: 18px;
  border: solid 1px #5bc2e7;
  background-color: #0067ba;
  cursor: pointer;
`

export const StyledPointer = styled.div`
  width: 10px;
  height: 10px;
  border-right: 3px solid;
  border-top: 3px solid;
  transform: rotate(-45deg);
  ${border}
`

export const ResetLinkStyle = styled.a`
  text-decoration: none;
  outline: none;
  ${color}
  ${layout}
  ${typography}
  ${border}
  ${space}
  ${shadow}
  ${position}
  ${flexbox}
`

export const SolidButton = styled(BaseButton)`
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`

export const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-5px);
  }
  60% {
    transform: translateY(-3px);
  }
`

export const StyledIcon = styled.div`
  ${space}
  ${color}
  ${flexbox}
  ${layout}
  ${typography}
  ${position}
  margin-left: calc(100vw / 2 - (30px / 2));
  -moz-animation: ${bounce} 2s 3;
  -webkit-animation: ${bounce} 2s 3;
  animation: ${bounce} 2s 3;
  bottom: 15px;

  
  `

export const LineSpan = styled.span`
  background: white;
  border-radius: 10px;
  height: 2px;
  position: absolute;
  top: 0;
  width: 100%;
`
export const LineLeft = styled(LineSpan)`
  ${color}
  transform: rotate(45deg);
`
export const LineRight = styled(LineSpan)`
  ${color}
  transform: translateX(12.8px) rotate(-45deg);
`
