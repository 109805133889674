import React from 'react'
import { useMobile } from '../../hooks'
import NavigationDesktop from './NavigationDesktop'
import NavigationMobile from './NavigationMobile'

const Navigation = ({ mobileOnly = false, navItems, closure, ...rest }) => {
  const [isMobile] = useMobile()

  if (isMobile === undefined) return null // isMobile === undefined for SSR
  if (!mobileOnly && isMobile === false)
    return <NavigationDesktop navItems={navItems} closure={closure} {...rest} />
  return <NavigationMobile navItems={navItems} {...rest} />
}

export default Navigation
