import React from 'react'
import { ThemeProvider } from 'styled-components'
import TitleAndMetaTags from '../../../../common/components/TitleAndMetaTags'
import { GlobalStyle } from './styles'
import Container from '../../../../common/components/Container'
import Navigation from '../Navigation'
import Footer from '../Footer'
import theme from '../../../../common/themes/index'

const Layout = ({ children, config, ...rest }) => {
  if (typeof window !== 'undefined') {
    // eslint-disable-next-line global-require
    require('smooth-scroll')('a[href*="#"]')
  }

  return (
    <ThemeProvider theme={theme[config.theme]}>
      <GlobalStyle />
      <TitleAndMetaTags config={config} {...rest} />
      <Navigation />
      <Container>{children}</Container>
      <Footer />
    </ThemeProvider>
  )
}

export default Layout
