import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledNavWrapper,
  StyledNavigation,
  StyledNavigationItemsContainer,
} from '../styles'
import NavigationItem from '../components/NavigationItem'
import Notification from '../components/notification'
import NavigationLogo from '../components/NavigationLogo'
import { zIndices } from '../../../themes'
import { SolidColorButton } from '../../Button'

const NavigationDesktopSimple = ({
  navItems,
  closure,
  logo,
  alt,
  theme,
  homeSlug,
  preview,
}) => {
  const isActive = slug =>
    window?.location?.pathname.split('/')[1] === `${slug}`

  return (
    <StyledNavWrapper
      data-testid="navigation-desktop-simple"
      role="navigation"
      position="fixed"
      bg={`${theme}.bg`}
      color={`${theme}.text`}
      zIndex={zIndices.navigation}
      py={2}
    >
      <StyledNavigation px={4} justifyContent="space-between" maxWidth="95%">
        <NavigationLogo
          logo={logo}
          alt={alt}
          homeSlug={homeSlug}
          maxLogoWidth="160px"
        />
        <Notification closure={closure} />
        <StyledNavigationItemsContainer>
          {navItems &&
            navItems.map(({ id, displayText, navigationLink }) => (
              <NavigationItem
                key={id}
                displayText={displayText}
                {...navigationLink}
                theme={theme}
                isActive={isActive(navigationLink.slug)}
                preview={preview}
                changeTextColorOnHover
                fontSize={5}
                fontWeight={400}
              />
            ))}
          <SolidColorButton
            href="https://www.servicebot.io/"
            theme={theme}
            style={{ margin: '0 0 0 15px' }}
          >
            Member Login
          </SolidColorButton>
        </StyledNavigationItemsContainer>
      </StyledNavigation>
    </StyledNavWrapper>
  )
}

NavigationDesktopSimple.propTypes = {
  navItems: PropTypes.arrayOf(PropTypes.object),
  closure: PropTypes.object,
  logo: PropTypes.string,
  alt: PropTypes.string,
  theme: PropTypes.string,
  homeSlug: PropTypes.string,
  preview: PropTypes.bool,
}

export default NavigationDesktopSimple
