import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledList,
  StyledSocialLink,
  StyledMediaIcon,
  StyledListItem,
} from '../styles'

const FooterSocialMedia = ({ socialMediaLinks, maxWidth = '35px' }) => {
  if (!socialMediaLinks) return null
  return (
    <StyledList>
      {socialMediaLinks.map(({ id, url, src, alt }) => (
        <StyledListItem
          key={id}
          mx={[2, 2, 1]}
          justifyContent="center"
          alignItems="center"
        >
          <StyledSocialLink
            href={url}
            mx={3}
            my={3}
            fontSize={6}
            target="__blank"
          >
            <StyledMediaIcon src={src} alt={alt} maxWidth={maxWidth} />
          </StyledSocialLink>
        </StyledListItem>
      ))}
    </StyledList>
  )
}

FooterSocialMedia.propTypes = {
  socialMediaLinks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      src: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    })
  ),
}
export default FooterSocialMedia
