import { css, createGlobalStyle } from 'styled-components'
import { theme } from '../../../../common/themes/coh'

export const fontStack = css`
  font-family: 'Catamaran', 'Arial', 'Verdana', '-apple-system',
    BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  font-style: normal;
`

export const GlobalStyle = createGlobalStyle`
html {
  ${fontStack};
  width: 100%; 
  height: 100%;

}
body {
  color: ${theme.colors.light.text};
  background-color: black;
  line-height: 1.2;
  margin: 0;
}
  
a {
  color: currentColor;
  text-decoration: none;
}

.wrapper {
  width: calc(100% - 10vmin);
  margin: 0 auto;
  padding: 5vmin 0;
}
.push::before {
  display:block;
  content: '';
  height:55px;
}


`
