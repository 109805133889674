import React from 'react'
import PropTypes from 'prop-types'
import NavigationDesktopSpread from './NavigationDesktopSpread'
import NavigationDesktopSimple from './NavigationDesktopSimple'
import { useComponentTheme } from '../../../hooks'

const NavigationDesktop = ({ navType, theme: themeBool, ...props }) => {
  const { theme } = useComponentTheme(themeBool)

  switch (navType) {
    case 'spreadEvenly':
      return <NavigationDesktopSpread theme={theme} {...props} />
    case 'simpleR2L':
    case 'simpleL2R':
    default:
      return <NavigationDesktopSimple theme={theme} {...props} />
  }
}

NavigationDesktop.propTypes = {
  navType: PropTypes.string,
  theme: PropTypes.bool,
}

export default NavigationDesktop
