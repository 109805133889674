import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledMobileOverlayContainer,
  StyledNavigationItemsContainerMobile,
  StyledCategoryHeader,
  StyledNavList,
  StyledNavigationItem,
  StyledContainer,
} from '../styles'
import NavigationItemMobile from './NavigationItemMobile'
import NavigationLogo from './NavigationLogo'

import { categorize } from '../../../utils'
import Notification from './notification'

const NavigationMobileOverlay = ({
  navItems,
  toggled,
  openOverlay,
  closeOverlay,
  toggledSubNav,
  slideDirection = 'left',
  theme,
  logo,
  alt,
  showLogoOnOverlay = false,
  stackText,
  preview,
  navbarOffsetTop = '80px',
  overlayLeftPadding = 3,
  showClosure = false,
}) => {
  const { categories, categoryMap, uncategorizedList } = categorize(
    navItems,
    'category'
  )

  return (
    <StyledMobileOverlayContainer
      bg={`${theme}.bg`}
      color={`${theme}.text`}
      opacity=".925"
      position="fixed"
      toggled={toggled}
      slideDirection={slideDirection}
    >
      <StyledNavigationItemsContainerMobile
        ml={overlayLeftPadding}
        pb={6}
        fontSize={0}
        position="absolute"
        top={navbarOffsetTop}
        bg={`${theme}.bg`}
        color={`${theme}.text`}
      >
        {showLogoOnOverlay && <NavigationLogo logo={logo} alt={alt} />}
        {showClosure && (
          <StyledContainer mt={4} mb={3}>
            <Notification />
          </StyledContainer>
        )}
        <StyledNavList
          bg={`${theme}.bg`}
          color={`${theme}.text`}
          my={showLogoOnOverlay && 3}
          fontFamily="nav"
        >
          {uncategorizedList &&
            uncategorizedList.map(({ id, navigationLink, displayText }) => (
              <NavigationItemMobile
                key={id}
                displayText={displayText}
                {...navigationLink}
                openOverlay={openOverlay}
                closeOverlay={closeOverlay}
                isMobile
                uncategorized
                stackText={stackText}
                toggledSubNav={toggledSubNav}
                color={toggledSubNav && 'transparent'}
                preview={preview}
                navbarOffsetTop={navbarOffsetTop}
                theme={theme}
              />
            ))}
          {categories &&
            categories.map(c => (
              <>
                <StyledNavigationItem>
                  <StyledCategoryHeader
                    color={toggledSubNav && 'transparent'}
                    fontFamily="nav"
                    my={3}
                  >
                    {c}
                  </StyledCategoryHeader>
                </StyledNavigationItem>
                {categoryMap[c].map(({ id, navigationLink, displayText }) => (
                  <NavigationItemMobile
                    key={id}
                    {...navigationLink}
                    displayText={displayText}
                    openOverlay={openOverlay}
                    closeOverlay={closeOverlay}
                    isMobile
                    stackText={stackText}
                    toggledSubNav={toggledSubNav}
                    color={toggledSubNav && 'transparent'}
                    preview={preview}
                    theme={theme}
                  />
                ))}
              </>
            ))}
        </StyledNavList>
      </StyledNavigationItemsContainerMobile>
    </StyledMobileOverlayContainer>
  )
}

NavigationMobileOverlay.propTypes = {
  navItems: PropTypes.arrayOf(PropTypes.object),
  toggledSubNav: PropTypes.bool,
  openOverlay: PropTypes.func,
  closeOverlay: PropTypes.func,
  slideDirection: PropTypes.string,
  theme: PropTypes.string,
  logo: PropTypes.string,
  alt: PropTypes.string,
  showLogoOnOverlay: PropTypes.bool,
  stackText: PropTypes.bool,
  preview: PropTypes.bool,
  navbarOffsetTop: PropTypes.string,
  overlayLeftPadding: PropTypes.number,
}

export default NavigationMobileOverlay
