import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledNavigationIcon,
  StyledNavigationLink,
  StyledSvg,
  StyledSvgContainer,
} from '../styles'

const NavigationLogo = ({
  logo,
  alt = 'logo',
  maxLogoWidth = '140px',
  order,
  visibility,
  homeSlug = '/',
}) => {
  return (
    <StyledNavigationIcon order={order} visibility={visibility}>
      <StyledNavigationLink to={homeSlug}>
        <StyledSvgContainer maxWidth={maxLogoWidth}>
          <StyledSvg src={logo} alt={alt} width={maxLogoWidth} />
        </StyledSvgContainer>
      </StyledNavigationLink>
    </StyledNavigationIcon>
  )
}

NavigationLogo.propTypes = {
  logo: PropTypes.string,
  alt: PropTypes.string,
  maxLogoWidth: PropTypes.string,
  order: PropTypes.number,
  visibility: PropTypes.string,
  homeSlug: PropTypes.string,
}

export default NavigationLogo
