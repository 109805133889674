import styled from 'styled-components'
import { A, OpaqueButton } from '../Button'
import {
  space,
  position,
  color,
  typography,
  border,
  layout,
  flexbox,
  background,
} from 'styled-system'

export const StyledContainer = styled.div`
  box-sizing: border-box;
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
`

export const StyledNavWrapper = styled.nav`
  text-transform: uppercase;
  font-family: 'Open Sans', 'Arial', 'Verdana', '-apple-system',
    BlinkMacSystemFont, sans-serif;
  font-style: normal;
  font-weight: 300;
  width: 100%;
  top: 0;
  box-sizing: border-box;
  ${space}
  ${position}
  ${color}

  & a {
    outline: none;
    text-decoration: none;
  }
`

export const StyledNavigation = styled.div`
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  justify-content: flex-start;
  align-items: center;
  max-width: 1180px;
  ${layout}
  ${flexbox}
  ${space}
`

export const StyledNavigationIcon = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  transition: opacity 0.25s;
  ${({ logoHeight }) => logoHeight && `height: ${logoHeight};`}
  ${({ visibility }) =>
    visibility === 'hidden'
      ? `visibility: hidden; opacity: 0;`
      : `visibility: visible; opacity: 1;`}
  ${position}
  ${space}
  ${flexbox}
`

export const StyledNavigationItemsContainer = styled.ul`
  display: inline-flex;
  align-items: center;
  justify-content: ${({ spreadNavigationItemsEvenly }) =>
    spreadNavigationItemsEvenly ? 'space-between' : 'flex-end'};
  width: 80%;
  margin: 0;
  padding: 0;
  ${space}
`

export const StyledNavList = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  color: white;
  margin: 0;
  padding: 0;
  ${position}
  ${color}
  ${typography}
  ${space}
  `

export const StyledNavigationItem = styled.li`
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  text-transform: none;
  ${typography}
  ${space}
  ${layout}
`
export const Cell = styled.span`
${typography}
${space}
${layout}
`

export const StyledNavigationAnchor = styled.a`
  color: currentColor;
  transition: all ${({ transitionDuration }) => transitionDuration || '0.25s'};
  ${space}
  ${typography}

  &:hover {
    ${({ hoverBgChange }) =>
      hoverBgChange && `background-color: ${hoverBgChange}`};
    ${({ hoverGrowText }) => hoverGrowText && `transform: scale(1.1);`}
  }
`

export const StyledNavigationLink = styled(A)`
  display: block;
  cursor: pointer;
  color: currentColor;
  transition: all ${({ transitionDuration }) => transitionDuration || '0.25s'};
  ${space}
  ${typography}
  ${color}

   ${({ textTransform }) =>
     textTransform && `text-transform: ${textTransform};`}

  &:hover {
    ${({ hoverBgChange }) =>
      hoverBgChange && `background-color: ${hoverBgChange}`};
    ${({ hoverGrowText }) => hoverGrowText && `transform: scale(1.1);`}
  }
`

export const StyledMenuBtnContainer = styled.div`
  cursor: pointer;
  display: inline-block;
  transition-property: opacity, -webkit-filter;
  transition-property: opacity, filter;
  transition-property: opacity, filter, -webkit-filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  overflow: visible;
  ${flexbox}
  ${space}
  ${color}
`

export const StyledBtnBox = styled.span`
  display: inline-block;
  position: relative;
  ${layout}
`

export const StyledMenuBtnLine = styled.span`
  display: block;
  margin-top: -2px;
  height: 2px;
  position: absolute;
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  ${({ toggled }) =>
    toggled &&
    `background: #fff;
  -webkit-transform: translate3d(0,-10px,0) rotate(-45deg);
  transform: translate3d(0,-10px,0) rotate(-45deg);
  transition-delay: .22s;
  transition-timing-function: cubic-bezier(.215,.61,.355,1);`}
  ${color}
  ${layout}
  ${position}
  ${({ large, width, toggled }) =>
    large && !toggled && `width: ${width.split('px')[0] * 0.8}px;`}

  &::before {
    height: 2px;
    background-color: #000;
    position: absolute;
    content: '';
    display: block;
    background: inherit;
    top: ${({ large }) => (large ? '-12px' : '-8px')};
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
      -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
      transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
      transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19),
      -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    ${({ toggled }) =>
      toggled &&
      `background: #fff;
    top: 0;
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    transition: top .1s .16s cubic-bezier(.33333,0,.66667,.33333),-webkit-transform .13s .25s cubic-bezier(.215,.61,.355,1);
    transition: top .1s .16s cubic-bezier(.33333,0,.66667,.33333),transform .13s .25s cubic-bezier(.215,.61,.355,1);
    transition: top .1s .16s cubic-bezier(.33333,0,.66667,.33333),transform .13s .25s cubic-bezier(.215,.61,.355,1),-webkit-transform .13s .25s cubic-bezier(.215,.61,.355,1);`}
    ${layout}
    ${position}
  }

  &::after {
    height: 2px;
    background-color: #000;
    position: absolute;
    content: '';
    display: block;
    background: inherit;
    top: ${({ large }) => (large ? '-24px' : '-16px')};
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
      opacity 0.1s linear;

    ${({ toggled }) =>
      toggled &&
      `background: #fff;
    top: 0;
    opacity: 0;
    transition: top .2s cubic-bezier(.33333,0,.66667,.33333),opacity .1s .22s linear;`}
    ${layout}
    ${position}
  }
`

export const StyledIcon = styled.img`
  ${space}
  ${color}
`

export const StyledSvgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${layout}
`

export const StyledSvg = styled.img`
  height: auto;
  ${space}
  ${color}
  ${layout}
`

export const StyledNotificationTextBox = styled.div`
  position: absolute;
  visibility: hidden;
  opacity: 0;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, 16px);
  text-transform: none;
  min-width: 150px;
  transition: all 0.25s;
  ${border}
  ${color}
  ${space}

  &::before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(0%, -90%);
    margin-left: -15px;
    border-width: 15px;
    border-style: solid;
    border-color: transparent transparent
      ${({ bg }) => bg || 'transparent'} transparent;
  }
`

export const StyledNotificationIcon = styled(StyledIcon)`
  width: 36px;
  height: 36px;
  ${space}
`

export const StyledNotificationText = styled.p`
  ${typography}
  ${color}
`

export const StyledNotificationContent = styled.div`
  ${color}
  ${space}
  ${position}
  ${layout}
  ${border}
  ${flexbox}
`

export const StyledNotification = styled(OpaqueButton)`
  ${color}
  ${space}
  ${position}
  ${layout}
`

// Mobile
export const StyledNavWrapperMobile = styled(StyledNavWrapper)`
  background-color: transparent;
  transition: transform 1s;
  ${color}
  ${position}

  ${({ shrink }) =>
    shrink ? `transform: translateY(-100%);` : 'transform: translateY(0%);'}
`

export const StyledCategoryHeader = styled.h4`
  text-transform: lowercase;
   ${typography}
   ${color}
   ${space}
`

export const StyledMenuButton = styled(StyledIcon)`
  width: 27px;
  height: 27px;
  cursor: pointer;
  transition: opacity 0.25s;
  // opacity: ${({ display }) => (display ? '100%' : '0%')};
  ${position}
`

export const StyledMobileOverlayContainer = styled.div`
  width: 100vw;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  height: 100vh;
  z-index: 5;
  top: 0;
  bottom: 0;
  transition: transform 0.25s;
  ${color}
  ${position}
  
  &::-webkit-scrollbar {
    display: none;
  }

  ${({ toggled, slideDirection }) =>
    toggled
      ? `transform: translateX(0%);`
      : `transform: translateX(${
          slideDirection === 'right' ? '-100%' : '100%'
        });`}

  & a {
    outline: none;
    text-decoration: none;
  }
`

export const StyledClosingContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  ${space}
`

export const StyledNavigationItemsContainerMobile = styled(StyledNavWrapper)``

export const DropDownMenu = styled.li`
list-style-type:none;
${position}
${color}
${typography}
${space}
${background}
${flexbox}

  position: fixed;
  float: none;
  top: 74px;
  left: 0px;
  z-index: 1000;
  border: none;
  border-radius: 0px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  min-height: 50px;
  width: 60vw;
  transition: all 0.3s ease-out;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  pointer-events: auto;
  backdrop-filter: blur(5px);
  text-transform: none;
  margin: 0 20vw;
  text-align: center;
  background: rgba(0,114,206,1);
  box-sizing: border-box;
`
export const DropDownNavContainer = styled.ul`
  display: inline;
  box-sizing: border-box;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
`

const getWidth = arrLength => Math.floor(100 / arrLength)

const getMarginFromIndex = (arrLength, arrIndex) => {
  let percentUnit = getWidth(arrLength)
  let howManyPercents = percentUnit * arrIndex
  let percent = howManyPercents + '%'
  return percent
}

export const DropDownNavItem = styled.li`
list-style-type: none;
margin: 0;
padding: 0;
cursor: pointer;
display: inline;
text-align: center;
${flexbox}
${position}
${color}
${typography}
${space}
${background}
${flexbox}


&:hover {
  ~ hr {
    margin-left: ${({ navItemsLength, index }) =>
      getMarginFromIndex(navItemsLength, index) || '0'};
    background: #5BC2E7;
  }
  & a {
    color: ${({ subNavPage }) => !subNavPage && '#5BC2E7'};
  }
}
`

export const SlidingUnderline = styled.hr`
  height: 0.25rem;
  width: ${({ navItemsLength }) => `${getWidth(navItemsLength)}%` || '0'};
  margin: 0;
  margin-left: ${({ navItemsLength, activeIndex }) =>
    activeIndex && getMarginFromIndex(navItemsLength, activeIndex)};
  background: ${({ active }) => (active ? '#5BC2E7' : '#0072ce')};
  border: none;
  transition: 0.3s ease-in-out;
`
export const DropDownNavLink = styled(A)`
  display: inline-block;
  width: ${({ navItemsLength }) => `${getWidth(navItemsLength)}%` || '0'};
  padding: 1rem;
  margin: 0;
  box-sizing: border-box;
  text-decoration: none;
  color: ${({ active }) => active && '#5BC2E7'};
`

export const StyledText = styled.p`
  margin: 0;
  padding: 0;
  ${({ textTransform }) => textTransform && `text-transform: ${textTransform};`}
  ${typography}
  ${space}
  ${color}
`

export const StyledListItem = styled.li`
  margin: 0;
  padding: 0;
  list-style-type: none;
  ${space}
  ${layout}
  ${position}
`

export const StyledArrow = styled.div`
  width: 12px;
  height: 12px;
  border-right: 3px solid;
  border-top: 3px solid;
  border-radius: 2px;
  transform: rotate(-135deg);
`
export const StyledButtonContainer = styled.div`
  cursor: pointer;
  ${space}
  ${position}
  ${flexbox}
  ${layout}
`
