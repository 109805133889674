import geoViewport from '@mapbox/geo-viewport'
import { Children, cloneElement } from 'react'
import axios from 'axios'
import cheerio from 'cheerio'
export const hasWindow = typeof window !== 'undefined' && window
export const isUnsupported =
  hasWindow &&
  navigator !== undefined &&
  (/MSIE 9/i.test(navigator.userAgent) ||
    /MSIE 10/i.test(navigator.userAgent) ||
    /Trident/i.test(navigator.userAgent))

export const isDebug = hasWindow && process.env.NODE_ENV === 'development'

/**
 * Calculate the appropriate center and zoom to fit the bounds, given padding.
 * @param {Element} - map DOM node used to calculate height and width in screen pixels of map
 * @param {Array(number)} bounds - [xmin, ymin, xmax, ymax]
 * @param {float} padding - proportion of calculated zoom level to zoom out by, to pad the bounds
 */
export const getCenterAndZoom = (mapNode, bounds, padding = 0) => {
  const { width, height } = mapNode
  const viewport = geoViewport.viewport(
    bounds,
    [width, height],
    undefined,
    undefined,
    undefined,
    true
  )

  // Zoom out slightly to pad around bounds

  const zoom = Math.max(viewport.zoom - 1, 0) * (1 - padding)

  return { center: viewport.center, zoom }
}

export const getVimeoSrc = str => {
  return str
}
export const getYoutubeEmbed = video => {
  if (video.includes('watch?v=')) {
    video = video.replace('watch?v=', 'embed/')
  }
  if (video.includes('&feature=emb_logo')) {
    video = video.replace('&feature=emb_logo', '')
  }
  return video
}

export const getVideoType = url => {
  if (url && typeof url === 'string' && url.includes('youtube')) {
    url = getYoutubeEmbed(url)
  }
  if (url && typeof url === 'string' && url.includes('vimeo')) {
    url = getVimeoSrc(url)
  }
  // going to add other external links in the future
  return url
}

export const shareSocial = (platform, location, name) => {
  switch (platform) {
    case 'facebook':
      return `https://www.facebook.com/sharer.php?u=${location}`
    case 'linkedin':
      return `https://www.linkedin.com/shareArticle?mini=true&url=${location}&title=ayzenberg : players`
    case 'twitter':
      return `https://twitter.com/share?text=Meet: ${name} &url=${location}&hashtags=anetwork, create`
    case 'reddit':
      return `https://www.reddit.com/submit?url=${location}&title=Meet: ${name}`
    case 'pinterest':
      return `http://pinterest.com/pin/create/link/?url=${location}`
    case 'email':
      return `https://www.addthis.com/tellfriend_v2.php?url=${location}`
    default:
      return `https://${platform}.com/`
  }
}

export const categorize = (items, categoryName) => {
  const categoryMap = {}
  if (items) {
    items.forEach(item => {
      const category = item[categoryName]
      if (!categoryMap[category]) {
        categoryMap[category] = []
      }
      categoryMap[category].push(item)
    })
  }
  const categories = Object.keys(categoryMap).filter(
    c => c !== 'undefined' && c !== 'None'
  )
  const undefinedList = categoryMap['undefined'] || []
  const noneList = categoryMap['None'] || []
  const uncategorizedList = [...undefinedList, ...noneList]
  return { categories, categoryMap, uncategorizedList }
}

export const formatDate = date => {
  var fdate = new Date(date)
  var months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  return (
    months[fdate.getMonth()] +
    ' ' +
    fdate.getDate() +
    ', ' +
    fdate.getFullYear()
  )
}

export const trunc = (str, len = 100) => {
  if (typeof str !== 'string') {
    return ''
  }
  if (str.length <= len) {
    return str
  }
  return str.substr(0, len - 1) + '...'
}

export const addQueryDataToBlade = ({ bladeObj, key, value }) => {
  return (bladeObj[key] = value)
}

export const addQueryDataBasedOnLayout = ({
  bladeLayout,
  bladeObj,
  key,
  value,
}) => {
  if (bladeObj.layout && bladeObj.layout === bladeLayout) {
    addQueryDataToBlade({ bladeObj, key, value })
  }
}

export const addQueryDataToBlades = ({
  blades,
  news,
  parentNews,
  updates,
  closures,
  closuresBg,
  projectNavigation,
  logos,
  projects,
  mapItems,
  siteInfo,
}) => {
  if (!blades) {
    return []
  }
  for (let i = 0; i < blades.length; i++) {
    // if the blade is the first blade,
    // display the scroll down icon
    // otherwise do not display the scroll down icon
    if (i === 0 && blades[i].__typename === 'ContentfulBladeText') {
      blades[i].displayScrollIcon = true
    }

    if (blades[i].layout && blades[i].layout === 'News') {
      let singleNews = { news: [news[0]], parentSlug: parentNews[0].parentSlug }

      blades[i].news = singleNews
    }

    let defaultProjectOpenId =
      projectNavigation?.navItems[0].navigationLink.id || ''
    let defaultProjectObj =
      projects?.filter(item => item.id === defaultProjectOpenId)[0] || {}

    addQueryDataBasedOnLayout({
      bladeLayout: 'Updates',
      bladeObj: blades[i],
      key: 'updates',
      value: {
        updates: updates,
        parentSlug: parentNews && parentNews?.[0]?.parentSlug,
      },
    })
    addQueryDataBasedOnLayout({
      bladeLayout: 'Closures',
      bladeObj: blades[i],
      key: 'closures',
      value: closures,
    })
    addQueryDataBasedOnLayout({
      bladeLayout: 'Closures',
      bladeObj: blades[i],
      key: 'closuresBg',
      value: closuresBg,
    })
    addQueryDataBasedOnLayout({
      bladeLayout: 'News',
      bladeObj: blades[i],
      key: 'news',
      value: { news: news, parentSlug: parentNews?.[0]?.parentSlug },
    })
    addQueryDataBasedOnLayout({
      bladeLayout: 'News & Updates',
      bladeObj: blades[i],
      key: 'news',
      value: {
        news: news,
        parentSlug: parentNews && parentNews?.[0]?.parentSlug,
      },
    })
    addQueryDataBasedOnLayout({
      bladeLayout: 'News & Updates',
      bladeObj: blades[i],
      key: 'updates',
      value: {
        updates: updates,
        parentSlug: parentNews && parentNews?.[0]?.parentSlug,
      },
    })
    addQueryDataBasedOnLayout({
      bladeLayout: 'Expansion Timeline - Projects',
      bladeObj: blades[i],
      key: 'items',
      value: projectNavigation?.navItems,
    })
    addQueryDataBasedOnLayout({
      bladeLayout: 'Home Logos',
      bladeObj: blades[i],
      key: 'logos',
      value: logos,
    })
    addQueryDataBasedOnLayout({
      bladeLayout: 'Expansion Timeline - Projects',
      bladeObj: blades[i],
      key: 'defaultProject',
      value: defaultProjectObj,
    })
    addQueryDataBasedOnLayout({
      bladeLayout: 'Map',
      bladeObj: blades[i],
      key: 'mapItems',
      value: mapItems,
    })
    addQueryDataBasedOnLayout({
      bladeLayout: 'Map',
      bladeObj: blades[i],
      key: 'siteInfo',
      value: siteInfo,
    })
    addQueryDataBasedOnLayout({
      bladeLayout: 'Map',
      bladeObj: blades[i],
      key: 'closures',
      value: closures,
    })
  }
  return blades
}
export const mappedContent = (c, type) => {
  let content = []
  if (!Array.isArray(c)) return null
  for (let i = 0; i < c.length; i++) {
    const contentCheck =
      c[i] &&
      c[i].data &&
      c[i].data.target &&
      c[i].data.target.sys &&
      c[i].data.target.sys.contentType &&
      c[i].data.target.sys.contentType.sys &&
      c[i].data.target.sys.contentType.sys.id === type
    if (contentCheck) {
      const val = c[i].data && c[i].data.target && c[i].data.target.fields
      content.push(val)
    } else if (
      c[i] &&
      c[i].content &&
      Array.isArray(c[i].content) &&
      c[i].content.length > 0
    ) {
      content = content.concat(mappedContent(c[i].content, type))
    }
  }
  return content
}
export const mappedString = (c, type) => {
  if (!c) return null

  let content = []
  if (!Array.isArray(c)) return null
  for (let i = 0; i < c.length; i++) {
    const contentCheck =
      c[i] &&
      c[i].value &&
      c[i].value &&
      c[i].value.length > 0 &&
      c[i] &&
      c[i].nodeType === type

    if (contentCheck) {
      let val = c[i] && c[i].value && c[i].value && c[i].value
      content.push(val)
    } else if (
      c[i] &&
      c[i].content &&
      Array.isArray(c[i].content) &&
      c[i].content.length > 0
    ) {
      content = content.concat(mappedString(c[i].content, type))
    }
  }
  return content
}

export const parseQueryString = queryVariable => {
  const query = window.location.search.substring(1)

  const variables = query.split('&')
  for (let i = 0; i < variables.length; i++) {
    let pair = variables[i].split('=')
    if (pair[0] === queryVariable) {
      return pair[1]
    }
  }
}

// determining blade vertical alignment
export const verticalObj = {
  Middle: 'center',
  Top: 'flex-start',
  Bottom: 'flex-end',
  center: 'center',
}
export const horizontalObj = {
  Center: 'center',
  Left: 'flex-start',
  Right: 'flex-end',
  center: 'center',
}

export const filterAssetsObjFromAssetsArrById = ({ id, assetsArr }) =>
  assetsArr.filter(item => item.id === id)[0]

export const filterAssetsObjFromAssetsArrByIdAndReturnFluid = ({
  id,
  assetsArr,
}) => {
  let newItem = assetsArr.filter(item => item.id === id)[0]

  if (newItem?.file?.url) {
    newItem.fluid = {
      src: newItem.file.url,
    }
  }
  return newItem
}

export const filterAssetsObjFromAssetsArrByIdAndReturnSrcString = ({
  id,
  assetsArr,
}) => {
  let newItem = assetsArr.filter(item => item.id === id)[0]

  if (newItem?.file?.url) {
    newItem.fluid = newItem.file.url
    return newItem
  }
  if (newItem?.src) {
    return newItem.src
  }
  return newItem
}

export const parseResponse = response => {
  const $ = cheerio.load(response.data)
  const scripts = $(`html > body > script`) // Code smells #40 and #42

  let id = 0

  if (scripts.get(0).attribs.type === `application/ld+json`) {
    id = 1
  }

  const jsonData = $(`html > body > script`)
    .get(id)
    .children[0].data.replace(/window\._sharedData\s?=\s?{/, `{`)
    .replace(/;$/g, ``)
  return JSON.parse(jsonData).entry_data
}

export const scrapeInstagramPosts = async ({ username }) => {
  return axios
    .get(`https://www.instagram.com/${username}/`)
    .then(response => {
      const data = parseResponse(response)
      const photos = []
      data.ProfilePage[0].graphql.user.edge_owner_to_timeline_media.edges.forEach(
        edge => {
          if (edge.node) {
            photos.push(edge.node)
          }
        }
      )
      return photos
    })
    .catch(err => {
      console.warn(`\nCould not fetch. Error: ${err}`)
      return null
    })
}

export const getInstagramPosts = async params => {
  const data = await scrapeInstagramPosts(params)
  return data
}

export const createPost = post => {
  return {
    username: post.username || post.owner.username,
    id: post.shortcode,
    likes: post.edge_liked_by?.count || post.like_count,
    caption: post.edge_media_to_caption?.edges[0].node.text || post.caption,
    // thumbnails: post.thumbnail_resources,
    // mediaType: post.__typename || post.media_type,
    // preview: post.display_url || post.thumbnail_url || post.media_url,
    original: post.display_url || post.media_url,
    width: 270,
    height: 270,
    timestamp:
      post.taken_at_timestamp || new Date(post.timestamp).getTime() / 1000,
    dimensions: post.dimensions,
    comments: post.edge_media_to_comment?.count || post.comments_count,
  }
}
export const formatPosts = ({ posts }) => posts.map(post => createPost(post))

export const parseChildren = (shortenDescription, arr) => {
  arr.forEach(c => {
    if (shortenDescription && c[1] !== '') arr = shortenDescription
  })
  return shortenDescription
}

export const defaultInstagramPosts = [
  {
    username: 'cityofhope',
    id: 'B-NMW74456',
    likes: 721,
    caption:
      "The Wishing Trees at each of our locations carry messages of health, healing and hope. Now more than ever, our wishes of strength and resilience take on new meaning for patients and families, and each other. What's your wish for the City of Hope community? Leave it here for everyone to see. We’re...",
    thumbnails: [
      {
        src:
          'https://scontent-sjc3-1.cdninstagram.com/v/t51.2885-15/e15/s150x150/94376898_1479261945586505_8953970121748963846_n.jpg?_nc_ht=scontent-sjc3-1.cdninstagram.com&_nc_cat=105&_nc_ohc=vkl5fJ98F48AX8J0HMr&oh=057eca1effa50529573e1399a2651cfe&oe=5ECD1CF4',
        config_width: 150,
        config_height: 150,
      },
      {
        src:
          'https://scontent-sjc3-1.cdninstagram.com/v/t51.2885-15/e15/s240x240/94376898_1479261945586505_8953970121748963846_n.jpg?_nc_ht=scontent-sjc3-1.cdninstagram.com&_nc_cat=105&_nc_ohc=vkl5fJ98F48AX8J0HMr&oh=95fa61528ca5198ae059f4ea404a5dd7&oe=5ECCA8F6',
        config_width: 240,
        config_height: 240,
      },
      {
        src:
          'https://scontent-sjc3-1.cdninstagram.com/v/t51.2885-15/e15/s320x320/94376898_1479261945586505_8953970121748963846_n.jpg?_nc_ht=scontent-sjc3-1.cdninstagram.com&_nc_cat=105&_nc_ohc=vkl5fJ98F48AX8J0HMr&oh=94da3f8c61ecebd1b7972b9d0863625b&oe=5ECBCD8C',
        config_width: 320,
        config_height: 320,
      },
      {
        src:
          'https://scontent-sjc3-1.cdninstagram.com/v/t51.2885-15/e15/s480x480/94376898_1479261945586505_8953970121748963846_n.jpg?_nc_ht=scontent-sjc3-1.cdninstagram.com&_nc_cat=105&_nc_ohc=vkl5fJ98F48AX8J0HMr&oh=e9c0f8f8a1fd3c39b933df5dd1490608&oe=5ECA804D',
        config_width: 480,
        config_height: 480,
      },
      {
        src:
          'https://scontent-sjc3-1.cdninstagram.com/v/t51.2885-15/e15/s640x640/94376898_1479261945586505_8953970121748963846_n.jpg?_nc_ht=scontent-sjc3-1.cdninstagram.com&_nc_cat=105&_nc_ohc=vkl5fJ98F48AX8J0HMr&oh=392ac71344b7417b65d2a1bf46f0f3c1&oe=5ECB64B1',
        config_width: 640,
        config_height: 640,
      },
    ],
    preview:
      'https://scontent-sjc3-1.cdninstagram.com/v/t51.2885-15/e15/s640x640/94376898_1479261945586505_8953970121748963846_n.jpg?_nc_ht=scontent-sjc3-1.cdninstagram.com&_nc_cat=105&_nc_ohc=vkl5fJ98F48AX8J0HMr&oh=392ac71344b7417b65d2a1bf46f0f3c1&oe=5ECB64B1',
    original:
      'https://scontent-sjc3-1.cdninstagram.com/v/t51.2885-15/e15/s640x640/94376898_1479261945586505_8953970121748963846_n.jpg?_nc_ht=scontent-sjc3-1.cdninstagram.com&_nc_cat=105&_nc_ohc=vkl5fJ98F48AX8J0HMr&oh=392ac71344b7417b65d2a1bf46f0f3c1&oe=5ECB64B1',
    timestamp: 1587673820,
    dimensions: { height: 1080, width: 1080 },
    comments: 24,
  },
  {
    username: 'cityofhope',
    id: 'B-NMW74n123',
    likes: 514,
    caption:
      'In these challenging times, our commitment to transform hope into reality for #cancer patients and their families is stronger than ever. #CityofHeroes',
    thumbnails: [
      {
        src:
          'https://scontent-sjc3-1.cdninstagram.com/v/t51.2885-15/e15/s150x150/94051501_133691958229017_6119735610662638469_n.jpg?_nc_ht=scontent-sjc3-1.cdninstagram.com&_nc_cat=109&_nc_ohc=nnCS-rwzUoQAX_NKLtA&oh=bbc810628417d3a86758123908f6c5e6&oe=5EC9CEA5',
        config_width: 150,
        config_height: 150,
      },
      {
        src:
          'https://scontent-sjc3-1.cdninstagram.com/v/t51.2885-15/e15/s240x240/94051501_133691958229017_6119735610662638469_n.jpg?_nc_ht=scontent-sjc3-1.cdninstagram.com&_nc_cat=109&_nc_ohc=nnCS-rwzUoQAX_NKLtA&oh=1addf48d7cba61caf9745b151efa4443&oe=5ECB886F',
        config_width: 240,
        config_height: 240,
      },
      {
        src:
          'https://scontent-sjc3-1.cdninstagram.com/v/t51.2885-15/e15/s320x320/94051501_133691958229017_6119735610662638469_n.jpg?_nc_ht=scontent-sjc3-1.cdninstagram.com&_nc_cat=109&_nc_ohc=nnCS-rwzUoQAX_NKLtA&oh=f117686ba33a35f2b8c35c5b359a6999&oe=5ECB4E55',
        config_width: 320,
        config_height: 320,
      },
      {
        src:
          'https://scontent-sjc3-1.cdninstagram.com/v/t51.2885-15/e15/s480x480/94051501_133691958229017_6119735610662638469_n.jpg?_nc_ht=scontent-sjc3-1.cdninstagram.com&_nc_cat=109&_nc_ohc=nnCS-rwzUoQAX_NKLtA&oh=333ab8a9e06c10ec5446252da5b88f4c&oe=5ECA938F',
        config_width: 480,
        config_height: 480,
      },
      {
        src:
          'https://scontent-sjc3-1.cdninstagram.com/v/t51.2885-15/sh0.08/e35/s640x640/94051501_133691958229017_6119735610662638469_n.jpg?_nc_ht=scontent-sjc3-1.cdninstagram.com&_nc_cat=109&_nc_ohc=nnCS-rwzUoQAX_NKLtA&oh=81e2694b9989e68d0337bd6f853848de&oe=5ECD2B23',
        config_width: 640,
        config_height: 640,
      },
    ],
    preview:
      'https://scontent-sjc3-1.cdninstagram.com/v/t51.2885-15/sh0.08/e35/s640x640/94051501_133691958229017_6119735610662638469_n.jpg?_nc_ht=scontent-sjc3-1.cdninstagram.com&_nc_cat=109&_nc_ohc=nnCS-rwzUoQAX_NKLtA&oh=81e2694b9989e68d0337bd6f853848de&oe=5ECD2B23',
    original:
      'https://scontent-sjc3-1.cdninstagram.com/v/t51.2885-15/sh0.08/e35/s640x640/94051501_133691958229017_6119735610662638469_n.jpg?_nc_ht=scontent-sjc3-1.cdninstagram.com&_nc_cat=109&_nc_ohc=nnCS-rwzUoQAX_NKLtA&oh=81e2694b9989e68d0337bd6f853848de&oe=5ECD2B23',
    timestamp: 1587594615,
    dimensions: { height: 1080, width: 1080 },
    comments: 13,
  },
  {
    username: 'cityofhope',
    id: 'B-dJ0zMphvD',
    likes: 1708,
    caption:
      "Let's continue to do our part. Together as one City of Hope family. 💙 #StayHomeSaveLives",
    thumbnails: [
      {
        src:
          'https://scontent-lax3-2.cdninstagram.com/v/t51.2885-15/e15/c257.0.565.565a/s150x150/91486831_210299170243842_8814297635057658133_n.jpg?_nc_ht=scontent-lax3-2.cdninstagram.com&_nc_cat=106&_nc_ohc=snEJzLA-dHoAX9QvfOv&oh=83dde10b79c08f5e29d42d9c589ed702&oe=5EC63A9B',
        config_width: 150,
        config_height: 150,
      },
      {
        src:
          'https://scontent-lax3-2.cdninstagram.com/v/t51.2885-15/e15/c257.0.565.565a/s240x240/91486831_210299170243842_8814297635057658133_n.jpg?_nc_ht=scontent-lax3-2.cdninstagram.com&_nc_cat=106&_nc_ohc=snEJzLA-dHoAX9QvfOv&oh=da171f43054891836ba47cd59e3f7389&oe=5EC71E51',
        config_width: 240,
        config_height: 240,
      },
      {
        src:
          'https://scontent-lax3-2.cdninstagram.com/v/t51.2885-15/e15/c257.0.565.565a/s320x320/91486831_210299170243842_8814297635057658133_n.jpg?_nc_ht=scontent-lax3-2.cdninstagram.com&_nc_cat=106&_nc_ohc=snEJzLA-dHoAX9QvfOv&oh=6397cad31059fc46d1dd4e573ee8975b&oe=5EC824EB',
        config_width: 320,
        config_height: 320,
      },
      {
        src:
          'https://scontent-lax3-2.cdninstagram.com/v/t51.2885-15/e15/c257.0.565.565a/s480x480/91486831_210299170243842_8814297635057658133_n.jpg?_nc_ht=scontent-lax3-2.cdninstagram.com&_nc_cat=106&_nc_ohc=snEJzLA-dHoAX9QvfOv&oh=df61b03c7cad641026f5002eb4e296d0&oe=5EC70CB1',
        config_width: 480,
        config_height: 480,
      },
      {
        src:
          'https://scontent-lax3-2.cdninstagram.com/v/t51.2885-15/e15/c257.0.565.565a/91486831_210299170243842_8814297635057658133_n.jpg?_nc_ht=scontent-lax3-2.cdninstagram.com&_nc_cat=106&_nc_ohc=snEJzLA-dHoAX9QvfOv&oh=75dec6ca8af2afd3e2d7484e811c1bb0&oe=5EC73608',
        config_width: 640,
        config_height: 640,
      },
    ],
    mediaType: 'GraphSidecar',
    preview:
      'https://scontent-lax3-2.cdninstagram.com/v/t51.2885-15/fr/e15/s1080x1080/91486831_210299170243842_8814297635057658133_n.jpg?_nc_ht=scontent-lax3-2.cdninstagram.com&_nc_cat=106&_nc_ohc=snEJzLA-dHoAX9QvfOv&oh=526f2f461b9028cc82d52e2954fce184&oe=5EC624FD',
    original:
      'https://scontent-lax3-2.cdninstagram.com/v/t51.2885-15/fr/e15/s1080x1080/91486831_210299170243842_8814297635057658133_n.jpg?_nc_ht=scontent-lax3-2.cdninstagram.com&_nc_cat=106&_nc_ohc=snEJzLA-dHoAX9QvfOv&oh=526f2f461b9028cc82d52e2954fce184&oe=5EC624FD',
    timestamp: 1585781191,
    dimensions: {
      height: 565,
      width: 1080,
    },
    comments: 56,
  },
  {
    username: 'cityofhope',
    id: 'B-XcyqiH6NN',
    likes: 1041,
    caption:
      "A heartfelt THANK YOU to City of Hope doctors and doctors around the world. We're grateful for you today and every day. #NationalDoctorsDay",
    thumbnails: [
      {
        src:
          'https://scontent-lax3-1.cdninstagram.com/v/t51.2885-15/e15/c257.0.565.565a/s150x150/91616715_211984350138828_3601106993226028450_n.jpg?_nc_ht=scontent-lax3-1.cdninstagram.com&_nc_cat=100&_nc_ohc=jJS6YIJE6aQAX8aG_7v&oh=f39e571f5d57fd4ca0029cec6732e352&oe=5EC6D0A3',
        config_width: 150,
        config_height: 150,
      },
      {
        src:
          'https://scontent-lax3-1.cdninstagram.com/v/t51.2885-15/e15/c257.0.565.565a/s240x240/91616715_211984350138828_3601106993226028450_n.jpg?_nc_ht=scontent-lax3-1.cdninstagram.com&_nc_cat=100&_nc_ohc=jJS6YIJE6aQAX8aG_7v&oh=223578ddcdbca620068cc90337fe32ce&oe=5EC93C69',
        config_width: 240,
        config_height: 240,
      },
      {
        src:
          'https://scontent-lax3-1.cdninstagram.com/v/t51.2885-15/e15/c257.0.565.565a/s320x320/91616715_211984350138828_3601106993226028450_n.jpg?_nc_ht=scontent-lax3-1.cdninstagram.com&_nc_cat=100&_nc_ohc=jJS6YIJE6aQAX8aG_7v&oh=d605a26173452f2704eadcf7d4783de9&oe=5EC87CD3',
        config_width: 320,
        config_height: 320,
      },
      {
        src:
          'https://scontent-lax3-1.cdninstagram.com/v/t51.2885-15/e15/c257.0.565.565a/s480x480/91616715_211984350138828_3601106993226028450_n.jpg?_nc_ht=scontent-lax3-1.cdninstagram.com&_nc_cat=100&_nc_ohc=jJS6YIJE6aQAX8aG_7v&oh=f683635df39143df73dc398400f4e58f&oe=5EC69809',
        config_width: 480,
        config_height: 480,
      },
      {
        src:
          'https://scontent-lax3-1.cdninstagram.com/v/t51.2885-15/e15/c257.0.565.565a/91616715_211984350138828_3601106993226028450_n.jpg?_nc_ht=scontent-lax3-1.cdninstagram.com&_nc_cat=100&_nc_ohc=jJS6YIJE6aQAX8aG_7v&oh=ecac5436cf37f1b79899d2c1f06e553d&oe=5EC5CE40',
        config_width: 640,
        config_height: 640,
      },
    ],
    mediaType: 'GraphImage',
    preview:
      'https://scontent-lax3-1.cdninstagram.com/v/t51.2885-15/fr/e15/s1080x1080/91616715_211984350138828_3601106993226028450_n.jpg?_nc_ht=scontent-lax3-1.cdninstagram.com&_nc_cat=100&_nc_ohc=jJS6YIJE6aQAX8aG_7v&oh=b97f7d942f6cc6d6263c9bfba2d4c186&oe=5EC5E7C5',
    original:
      'https://scontent-lax3-1.cdninstagram.com/v/t51.2885-15/fr/e15/s1080x1080/91616715_211984350138828_3601106993226028450_n.jpg?_nc_ht=scontent-lax3-1.cdninstagram.com&_nc_cat=100&_nc_ohc=jJS6YIJE6aQAX8aG_7v&oh=b97f7d942f6cc6d6263c9bfba2d4c186&oe=5EC5E7C5',
    timestamp: 1585589808,
    dimensions: { height: 565, width: 1080 },
    comments: 36,
  },
]

export const filterLinkedIds = ({ dataObj, key }) => {
  if (!dataObj.fields[key]) {
    return []
  }
  return dataObj.fields[key].map(({ sys }) => ({ id: sys.id }))
}

export const filterLinkedIdsArr = ({ arr }) => {
  if (!Array.isArray(arr)) {
    return []
  }
  return arr.map(({ sys }) => ({ id: sys.id }))
}

export const filterAssets = ({ assetsArr }) => {
  if (!assetsArr) {
    return []
  }
  return assetsArr.map(({ sys, fields }) => ({ id: sys.id, ...fields }))
}
export const formatImageMediaArr = ({ assets, mediaObj, assetId }) => {
  if (!assets || !mediaObj || !assetId) {
    return []
  } else {
    let thumbnail =
      mediaObj &&
      mediaObj.thumbnail &&
      filterAssetsObjFromAssetsArrById({
        id: mediaObj.thumbnail.sys.id,
        assetsArr: assets,
      })
    let srcContentful = filterAssetsObjFromAssetsArrById({
      id: assetId,
      assetsArr: assets,
    })

    let mediaObjWithAssets = {
      id: mediaObj.id,
      title: mediaObj.title,
      src: mediaObj.src,
      srcContentful: {
        id: srcContentful?.id,
        fluid: {
          src: srcContentful?.file?.url,
        },
      },
      thumbnail: {
        id: thumbnail?.id,
        fluid: {
          src: thumbnail?.file?.url,
        },
      },
    }
    return [mediaObjWithAssets]
  }
}
export const formatVideoMediaArr = ({ assets, mediaObj, assetId }) => {
  if (!assets || !mediaObj || !assetId) {
    return []
  } else {
    let thumbnail =
      mediaObj &&
      mediaObj.thumbnail &&
      filterAssetsObjFromAssetsArrById({
        id: mediaObj.thumbnail.sys.id,
        assetsArr: assets,
      })
    let srcContentful = filterAssetsObjFromAssetsArrById({
      id: assetId,
      assetsArr: assets,
    })
    let mediaObjWithAssets = {
      title: mediaObj.title,
      src: mediaObj.src,
      srcContentful: {
        id: srcContentful && srcContentful.id,
        file: {
          url: srcContentful && srcContentful?.file?.url,
        },
      },
      thumbnail: {
        id: thumbnail && thumbnail.id,
        fluid: {
          src: thumbnail && thumbnail.file.url,
        },
      },
    }
    return [mediaObjWithAssets]
  }
}
export const formatMediaArr = ({ assets, mediaObj, assetId }) => {
  if (!assets || !mediaObj) {
    return []
  } else if (!assetId) {
    let mediaObjWithAssets
    let thumbnail =
      mediaObj &&
      mediaObj.thumbnail &&
      filterAssetsObjFromAssetsArrById({
        id: mediaObj.thumbnail.sys.id,
        assetsArr: assets,
      })
    mediaObjWithAssets = {
      ...mediaObj,
      thumbnail: {
        id: thumbnail && thumbnail.id,
        fluid: {
          src: thumbnail && thumbnail.file.url,
        },
      },
    }

    return [mediaObjWithAssets]
  } else {
    let mediaObjWithAssets
    let thumbnail =
      mediaObj &&
      mediaObj.thumbnail &&
      filterAssetsObjFromAssetsArrById({
        id: mediaObj.thumbnail.sys.id,
        assetsArr: assets,
      })
    let srcContentful = filterAssetsObjFromAssetsArrById({
      id: assetId,
      assetsArr: assets,
    })
    mediaObjWithAssets = {
      autoPlay: mediaObj.autoPlay,
      title: mediaObj.title,
      src: mediaObj.src,
      srcContentful: {
        id: srcContentful?.id,
        fluid: {
          src: srcContentful?.file?.url,
        },
        file: {
          url: srcContentful && srcContentful?.file?.url,
        },
      },
      thumbnail: {
        id: thumbnail && thumbnail.id,
        fluid: {
          src: thumbnail && thumbnail.file.url,
        },
      },
    }

    return [mediaObjWithAssets]
  }
}

export const renderThumbnail = thumb => {
  if (thumb == null) {
    return ''
  } else if (thumb['en-US']) {
    return thumb['en-US'].fields?.file['en-US'].url
  } else if (thumb?.fields) {
    return thumb.fields?.file['en-US']?.url
  } else {
    return thumb?.fluid?.src
  }
}

export const renderImage = ({ mediaObj }) => {
  let image = {
    src: false,
    assetId: false,
    type: 'image',
    id: 'image-id',
    alt: '',
    thumbnail: false,
    autoPlay: false,
  }

  if (mediaObj == null) {
    return image
  } else if (mediaObj.srcUrl) {
    image.src = mediaObj.srcUrl['en-US']
      ? getVideoType(mediaObj.srcUrl['en-US'])
      : getVideoType(mediaObj.srcUrl)
    image.id = mediaObj.id
    image.thumbnail = renderThumbnail(mediaObj.thumbnail)
  } else if (mediaObj.srcContentful) {
    // if an internal image
    image.src = mediaObj.srcContentful.fluid
      ? mediaObj.srcContentful.fluid
      : mediaObj.srcContentful.file.url
    image.id = mediaObj.id
    image.assetId = mediaObj.srcContentful
      ? mediaObj.srcContentful.file.id
      : mediaObj.srcContentful?.id
    image.thumbnail = renderThumbnail(mediaObj.thumbnail)
  } else {
    return {}
  }
  return image
}
export const removeLocaleNesting = ({ node, localeKey }) => {
  if (node?.data?.target?.fields) {
    const handleRemoveLocale = ({ object, key }) =>
      Object.keys(object).map(k =>
        object && object[k] && object[k][key]
          ? { [k]: object[k][key] }
          : { [k]: object[k] }
      )
    let arrOfKeyValuePairs = handleRemoveLocale({
      object: node.data.target?.fields,
      key: localeKey,
    })
    let fieldsObj = Object.assign(...arrOfKeyValuePairs)
    node.data.target = {
      id: node.data.target.sys?.id
        ? node.data.target.sys.id
        : node.data.target.id,
      contentType: node.data?.target?.sys?.contentType?.sys?.id
        ? node.data?.target?.sys?.contentType?.sys?.id
        : node.data.target.contentType,
      fields: node.data.target?.fields && fieldsObj,
    }
  }
  return node
}

export const renderVideo = ({ mediaObj }) => {
  let video = {
    src: false,
    assetId: false,
    type: 'video',
    id: 'vid-id',
    alt: '',
    thumbnail: false,
    autoPlay: false,
  }

  if (mediaObj == null) {
    return video
  } else if (mediaObj.srcUrl) {
    // if external video
    video.src = mediaObj.srcUrl?.['en-US']
      ? getVideoType(mediaObj.srcUrl['en-US'])
      : getVideoType(mediaObj.srcUrl)
    video.type = 'video-external'
    video.alt = mediaObj.title?.['en-US']
    video.id = mediaObj.id
    video.thumbnail = renderThumbnail(mediaObj.thumbnail)
    video.autoPlay = mediaObj.autoPlay || mediaObj.autoPlay?.['en-US']
    return video
  } else if (mediaObj.srcContentful) {
    // if internal video
    video.src = mediaObj.srcContentful?.fields
      ? mediaObj.srcContentful.fields.file?.['en-US'].url
      : mediaObj.srcContentful?.file?.url
    video.assetId = mediaObj.srcContentful['en-US']
      ? mediaObj.srcContentful['en-US'].id
      : mediaObj.srcContentful?.id
    video.type = 'video-internal'
    video.alt = mediaObj.srcContentful['en-US']
      ? mediaObj.srcContentful['en-US'].fields.file['en-US'].title
      : mediaObj.title
    video.id = mediaObj.id
    video.thumbnail = renderThumbnail(mediaObj.thumbnail)
    video.autoPlay =
      mediaObj?.autoPlay?.['en-US'] === false
        ? false
        : mediaObj?.autoPlay?.['en-US'] || mediaObj.autoPlay
    return video
  } else {
    // otherwise return default video object
    return video
  }
}
export const renderBgTypeAndBg = ({ background, theme }) => {
  // we only need to check if the media obj is a video,
  // otherwise we can assume it is an image
  // for reference
  const imageBgTypes = ['Contentful - JPG', 'Contentful - PNG']
  const videoBgTypes = [
    'Contentful - MP4',
    'External - YouTube',
    'External - Vimeo',
  ]

  if (background == null) {
    // if we're getting a null arr or obj, default to color
    return {
      type: 'color',
      src: theme === 'dark' ? `${theme}.bgAlt` : `${theme}.bg`,
    }
  } else if (!Array.isArray(background)) {
    // if we're getting an inline entry
    return background.src
      ? videoBgTypes.includes(background.src['en-US']) ||
        videoBgTypes.includes(background.src) // only video has external url option or MP4 src
        ? renderVideo({ mediaObj: background })
        : renderImage({ mediaObj: background })
      : {
          type: 'color',
          src: theme === 'dark' ? `${theme}.bgAlt` : `${theme}.bg`,
        }
  } else if (videoBgTypes.includes(background[0]?.src)) {
    // if there is a video bg
    let vid = renderVideo({ mediaObj: background[0] })
    return vid
  } else if (imageBgTypes.includes(background[0]?.src)) {
    // if there is an image bg
    return renderImage({ mediaObj: background[0] })
  } else {
    // default to color
    return {
      type: 'color',
      src: theme === 'dark' ? `${theme}.bgAlt` : `${theme}.bg`,
    }
  }
}
export const renderChildrenWithPropsOnlyUseExtremelySelectively = ({
  propsObj,
  children,
}) => Children.map(children, child => cloneElement(child, { ...propsObj }))

export const handleSimplifyContentEntryToFields = ({ node, contentType }) => {
  if (!node) {
    return {}
  }
  if (node.data.uri) {
    return {
      href: node.data.uri,
    }
  }
  return node.data.target.fields
}

export const handleFindContentType = ({ node, childrenLength }) => {
  const linkContentTypesArray = ['hyperlink', 'project', 'news', 'page']

  if (!node?.data?.target) {
    return node.nodeType
  } else {
    let cT = node?.data?.target?.sys?.contentType?.sys?.id
    if (childrenLength >= 1 && linkContentTypesArray.includes(cT)) {
      return 'button'
    } else {
      return cT
    }
  }
}
export const handleDetermineModalContentType = ({ node }) => {
  if (!node) {
    return 'text'
  } else if (
    node.data?.target?.fields?.mediaReference?.['en-US']?.sys?.contentType?.sys
      ?.id
  ) {
    return node.data.target.fields.mediaReference['en-US'].sys.contentType.sys
      .id
  }
}

export const getParentPath = ({ childPath, contentType }) => {
  if (!contentType) {
    return childPath
  }
  if (contentType === 'project') {
    return `expansion-timelines/${childPath}`
  }
}

export const handleInternalRoutingSlug = ({ node }) => {
  if (!node) {
    return '/'
  }

  let nestedEntryQuery = node.data?.target?.sys?.contentType?.sys?.id
  let directEntryQuery = node.data?.target?.entry?.contentType

  if (directEntryQuery) {
    return getParentPath({
      childPath: node.data?.target.entry?.fields.slug,
      contentType: directEntryQuery,
    })
  } else if (nestedEntryQuery) {
    return getParentPath({
      childPath: node.data?.target.fields.slug['en-US'],
      contentType: nestedEntryQuery,
    })
  } else return '/'
}

export const handleSrc = ({ targetEntryFields, t }) => {
  const { assetId, type, src, thumbnail, alt, autoPlay } = renderBgTypeAndBg({
    background: targetEntryFields,
    theme: t,
  })
  return { assetId, type, src, thumbnail, alt, autoPlay }
}

const escapeStringForUrl = address => {
  return address.split(' ').join('+')
}

export const getDirections = ({
  destinationAddress,
  originAddress,
  travelMode = 'walking',
  dirflg = 'w',
}) => {
  const origin = originAddress && escapeStringForUrl(originAddress)
  const destination = escapeStringForUrl(destinationAddress)

  const googleParams = `destination=${destination}&travelmode=${travelMode}`

  const appleParams = `daddr=${destination}&dirflg=${dirflg}`

  // if it is an iphone an ipod or an ipad
  if (
    navigator.platform.indexOf('iPhone') != -1 ||
    navigator.platform.indexOf('iPod') != -1 ||
    navigator.platform.indexOf('iPad') != -1
  ) {
    // window.open(`maps://maps.google.com/maps/dir//${appleParams}}/`);
    window.open(`http://maps.apple.com/?${appleParams}`)
    // if it is an android or desktop
  } else {
    window.open(`https://maps.google.com/maps/dir/?api=1&${googleParams}/`)

    // window.open(`http://maps.google.com/maps/dir//${googleParams}/`);
  }
}

const getCenterLatitudeAndLongitude = coordinatesArr => {
  let longitude = 0
  let latitude = 0

  for (let i = 0; i < coordinatesArr.length; i++) {
    longitude += coordinatesArr[i][0]
    latitude += coordinatesArr[i][1]
  }
  return [latitude / coordinatesArr.length, longitude / coordinatesArr.length]
}

const getCenterPointAndFormat = coordinatesArr => {
  let centerCoordinates = getCenterLatitudeAndLongitude(coordinatesArr)
  return `${centerCoordinates[0]},${centerCoordinates[1]}`
}

export const getDirectionsGeoJson = ({
  destinationPolygonCoordinates,
  defaultAddress = '1500 E Duarte Rd, Duarte, CA 91010',
  travelMode = 'walking',
  dirflg = 'w',
}) => {
  const destination = destinationPolygonCoordinates
    ? getCenterPointAndFormat(destinationPolygonCoordinates)
    : defaultAddress

  const googleParams = `destination=${destination}&travelmode=${travelMode}`

  const appleParams = `daddr=${destination}&dirflg=${dirflg}`

  // if it is an iphone an ipod or an ipad
  if (
    navigator.platform.indexOf('iPhone') != -1 ||
    navigator.platform.indexOf('iPod') != -1 ||
    navigator.platform.indexOf('iPad') != -1
  ) {
    // window.open(`maps://maps.google.com/maps/dir//${appleParams}}/`);
    window.open(`http://maps.apple.com/?${appleParams}`)
    // if it is an android or desktop
  } else {
    window.open(`https://maps.google.com/maps/dir/?api=1&${googleParams}/`)

    // window.open(`http://maps.google.com/maps/dir//${googleParams}/`);
  }
}
