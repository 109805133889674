import React from 'react'
import PropTypes from 'prop-types'
import { StyledFooterWrapper, StyledFooter } from './styles'
import FooterLinks from './components/FooterLinks'
import FooterCopyright from './components/FooterCopyright'
import FooterLogo from './components/FooterLogo'
import { useComponentTheme } from '../../hooks'

const FooterSimple = ({
  theme: themeBool = true,
  groupByCategory = true,
  footerLinks = [],
  copyright = [],
  logo,
  alt,
}) => {
  const { theme, invertTheme } = useComponentTheme(themeBool)

  return (
    <StyledFooterWrapper
      data-testid="footer-simple"
      bg={`${theme}.bg`}
      color={`${theme}.text`}
    >
      <StyledFooter p={[4, 4, 4]} fontSize={6} fontFamily="nav">
        <FooterLogo logo={logo} alt={alt} />
        <FooterLinks
          footerLinks={footerLinks}
          groupByCategory={groupByCategory}
          hoverBorder
          fontSize={0}
          theme={theme}
        />
      </StyledFooter>
      <FooterCopyright
        copyright={copyright}
        bg={`${invertTheme}.bg`}
        color={`${invertTheme}.text`}
      />
    </StyledFooterWrapper>
  )
}

FooterSimple.propTypes = {
  theme: PropTypes.bool,
  groupByCategory: PropTypes.bool,
  footerLinks: PropTypes.arrayOf(PropTypes.object),
  copyright: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.string,
  alt: PropTypes.string,
}

export default FooterSimple
