import React, { useContext } from 'react'
import { Link } from 'gatsby'
// import PropTypes from 'prop-types'

import {
  SolidButton,
  StyledButton,
  StyledIconButton,
  StyledScrollButton,
  ResetLinkStyle,
  StyledTopTextContainer,
  StyledTopText,
  StyledPointer,
  NakedA,
  StyledIcon,
  LineLeft,
  LineRight,
} from './styles.js'
import useWindowReady from '../../hooks/useWindowReady.js'
import { ThemeContext } from 'styled-components'

const StyledA = props => (
  <NakedA
    color={props.theme && `${props.theme}.bg`} // reverse our theme colors
    bg={props.theme && `${props.theme}.text`} // reverse our theme colors
    borderColor={props.theme && `${props.theme}.border`}
    borderWidth={props.light ? 1 : 2}
    fontWeight={props.light ? 300 : 600}
    hover={
      props.light ? `${props.theme}.btnHoverAlt` : `${props.theme}.btnHover`
    }
    onClick={props.onClick && props.onClick}
    fontFamily="headings"
  >
    {props.children}
  </NakedA>
)

const StyledOpaqueButton = props => {
  const themePackage = useContext(ThemeContext)
  return (
    <StyledButton
      minWidth={props.minWidth}
      color={`${props.theme}.textInvert`} // reverse our theme colors
      fontSize={[8, 7, 7, 7, 7, 7, 7]}
      bg={`${props.theme}.bgInvert`} // reverse our theme colors
      borderColor={`${props.theme}.borderAlt`}
      borderWidth={props.borderWidth || (props.light ? 1 : 2)}
      borderRadius="32px"
      fontWeight={props.light ? 300 : 600}
      py={props.py ?? 2}
      px={props.px ?? 3}
      hover={props.hover || themePackage?.colors?.[props.theme]?.btnHover}
      hoverOpacity={props.theme === 'light' && 0.75}
      onClick={props.onClick && props.onClick}
      fontFamily="headings"
      margin={props.margin}
    >
      {props.children}
    </StyledButton>
  )
}

const StyledTransparentButton = props => {
  return (
    <StyledButton
      color={`${props.theme}.text`}
      fontSize={[9, 7, 7, 7, 7, 7, 7]}
      bg={`${props.theme}.btnBg` || 'transparent'}
      borderColor={`${props.theme}.borderAlt`}
      borderWidth="1px"
      borderRadius="32px"
      py={[2]}
      px={[3]}
      textShadow="0px 1px 4px #00000029"
      fontWeight={600}
      fontFamily="headings"
      hover={props.theme === 'dark' && '#0072ce9c'}
      marginTop="20px"
      marginBottom="20px"
      onClick={props.onClick && props.onClick}
    >
      {props.children}
    </StyledButton>
  )
}

const StyledTransparentIconButton = props => (
  <StyledIconButton
    color={`${props.theme}.text`}
    fontSize={[8, 7, 7, 7, 7, 7, 7]}
    bg="transparent"
    borderColor={`${props.theme}.borderAlt`}
    hover={`${props.theme}.btnHover`}
    borderWidth="2px"
    fontWeight={600}
    fontFamily="headings"
    onClick={props.onClick && props.onClick}
  >
    {props.children}
  </StyledIconButton>
)

const StyledOpaqueIconButton = props => (
  <StyledIconButton
    color={`${props.theme}.text`}
    fontSize={[8, 7, 7, 7, 7, 7, 7]}
    bg="transparent"
    borderColor={`${props.theme}.borderAlt`}
    hover={`${props.theme}.btnHover`}
    borderWidth="2px"
    fontWeight={600}
    fontFamily="headings"
    onClick={props.onClick && props.onClick}
  >
    {props.children}
  </StyledIconButton>
)

const StyledSolidButton = props => (
  <SolidButton
    color={`${props.theme}.text`}
    fontSize={[8, 7, 7, 7, 7, 7, 7]}
    bg={`${props.theme}.btn`}
    borderColor={`${props.theme}.borderAlt`}
    borderWidth="0px"
    fontWeight={400}
    fontFamily="body"
    borderRadius="5px"
    width="176px"
    height="46px"
    onClick={props.onClick && props.onClick}
  >
    {props.children}
  </SolidButton>
)

const handleLinkWrapping = (Component, props) => {
  const {
    theme,
    href,
    to,
    target,
    children,
    disabled,
    isLoading,
    ...rest
  } = props

  const button = (
    <Component disabled={disabled || isLoading} theme={theme} {...rest}>
      {children}
    </Component>
  )

  if (!props.children) return null

  if (href) {
    return (
      <ResetLinkStyle
        href={href}
        target={target || '_blank'}
        rel={!target ? 'noopener noreferrer' : undefined}
        data-testid="external-btn"
      >
        {button}
      </ResetLinkStyle>
    )
  }

  if (to) {
    return (
      <Link data-testid="internal-btn" to={to[0] !== '/' ? `/${to}` : to}>
        {button}
      </Link>
    )
  }
  return button
}

export const A = props => handleLinkWrapping(NakedA, props)
export const BasicA = props => handleLinkWrapping(StyledA, props)
export const ScrollButton = props =>
  handleLinkWrapping(StyledScrollButton, props)

export const TopButton = ({
  theme = 'dark',
  children,
  fontSize = 6,
  fontWeight = '500',
  ...rest
}) => {
  const windowReady = useWindowReady()

  const goToTop = () => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: 'smooth',
    })
  }
  if (!windowReady) return null

  return (
    <ScrollButton onClick={goToTop} data-testid="top-btn" {...rest}>
      <StyledTopTextContainer
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        p={2}
      >
        <StyledPointer borderColor="#5bc2e7" />
        <StyledTopText
          color={`${theme}.text`}
          fontWeight={fontWeight}
          fontSize={fontSize}
          m={0}
        >
          {children || 'Back to top'}
        </StyledTopText>
      </StyledTopTextContainer>
    </ScrollButton>
  )
}

export const DownButton = ({ theme = 'dark', bg, children, ...rest }) => {
  const windowReady = useWindowReady()

  const goDown = () => {
    window.scrollBy({
      top: window.innerHeight - window.innerHeight / 9.5,
      left: 0,
      behavior: 'smooth',
    })
  }
  if (!windowReady) return null

  return (
    <StyledIcon
      data-testid="down-btn"
      onClick={goDown}
      {...rest}
      height="20px"
      width="20px"
      position="absolute"
      margin
      zIndex={1}
    >
      <LineLeft bg={bg} />
      <LineRight bg={bg} />
    </StyledIcon>
  )
}

export const Button = props => handleLinkWrapping(StyledButton, props)

export const ThemeSwitchButton = props =>
  props.theme === 'dark'
    ? handleLinkWrapping(StyledTransparentButton, props)
    : handleLinkWrapping(StyledOpaqueButton, props)
export const OpaqueButton = props =>
  handleLinkWrapping(StyledOpaqueButton, props)
export const TransparentButton = props =>
  handleLinkWrapping(StyledTransparentButton, props)
export const OpaqueIconButton = props =>
  handleLinkWrapping(StyledOpaqueIconButton, props)
export const TransparentIconButton = props =>
  handleLinkWrapping(StyledTransparentIconButton, props)
export const SolidColorButton = props =>
  handleLinkWrapping(StyledSolidButton, props)

export default Button
