import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { StyledNavigation } from '../styles'
import NavigationMobileOverlay from '../components/NavigationMobileOverlay'
import NavigationLogo from '../components/NavigationLogo'
import NavigationMenuBtn from '../components/NavigationMenuBtn'
import NavigationWrapper from '../components/NavigationWrapper'

const NavigationMobileSpread = ({
  navItems,
  theme,
  alt,
  logo,
  preview,
  homeSlug,
}) => {
  const [overlayLevel, setOverlayLevel] = useState(0)
  const toggledSubNav = overlayLevel > 1

  const openOverlay = () => {
    setOverlayLevel(overlayLevel => overlayLevel + 1)
  }

  const closeOverlay = () => {
    setOverlayLevel(overlayLevel => overlayLevel - 1)
  }

  const resetOverlay = () => {
    setOverlayLevel(0)
  }

  return (
    <>
      <NavigationWrapper theme={theme} data-testid="navigation-mobile-spread">
        <StyledNavigation justifyContent="space-between">
          <NavigationLogo
            logo={logo}
            alt={alt}
            homeSlug={homeSlug}
            maxLogoWidth="140px"
            order={1}
            visibility="visible"
          />
          <NavigationMenuBtn
            theme={theme}
            toggled={overlayLevel > 0}
            handleClick={overlayLevel > 0 ? resetOverlay : openOverlay}
            large
            order={2}
          />
        </StyledNavigation>
      </NavigationWrapper>
      <NavigationMobileOverlay
        theme={theme}
        navItems={navItems}
        toggled={overlayLevel > 0}
        openOverlay={openOverlay}
        closeOverlay={closeOverlay}
        toggledSubNav={toggledSubNav}
        slideDirection="left"
        logo={logo}
        alt={alt}
        stackText
        preview={preview}
        showClosure
      />
    </>
  )
}

NavigationMobileSpread.propTypes = {
  navItems: PropTypes.arrayOf(PropTypes.object),
  theme: PropTypes.string,
  alt: PropTypes.string,
  logo: PropTypes.string,
  preview: PropTypes.bool,
  homeSlug: PropTypes.string,
}
export default NavigationMobileSpread
