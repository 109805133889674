import React from 'react'
import PropTypes from 'prop-types'
import { StyledButtonContainer, StyledArrow, StyledText } from '../styles'

// to do
const BackButton = ({ children, handleClick }) => {
  return (
    <StyledButtonContainer
      data-testid="back-button"
      onClick={handleClick}
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      ml={1}
      my={1}
    >
      <StyledArrow />
      <StyledText fontSize={7} fontWeight="500" p={2} textTransform="none">
        {children}
      </StyledText>
    </StyledButtonContainer>
  )
}

BackButton.propTypes = {
  handleClick: PropTypes.func,
}

export default BackButton
