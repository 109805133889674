import Icon1 from '../../../assets/footer1@3x.png'
import Icon2 from '../../../assets/footer2@3x.png'
import Icon3 from '../../../assets/footer3@3x.png'

export default [
  {
    "id": "1",
    "src": Icon1,
    "alt": "Icon1"
  },
  {
    "id": "2",
    "src": Icon2,
    "alt": "Icon2"
  },
  {
    "id": "3",
    "src": Icon3,
    "alt": "Icon3"
  }
]